import React, { useState, useEffect } from 'react';
import { AvailabilityDTO } from '../../../Lib/sdk/Availabilities_pb';
import { useForm } from 'react-hook-form';
import grpc from '../../grpc';
import { EventForm, FormFields } from './EventForm';
import { format } from 'date-fns';
import {
  Layout,
  Form,
  ButtonArea,
  Button,
  Error,
  ButtonSecondary,
  ButtonDanger,
} from '../../kit/Form';
import { combine, dateToTimestamp, toDate } from '../../grpc-lib';
import { confirm } from '../AvailabilityCard/Dialog';

async function updateAvailability(data: FormFields, availabilityId: string, repeats: boolean) {
  const response = grpc.availabilities.updateAvailability({
    id: availabilityId,
    startTime: dateToTimestamp(combine(data.startDate, data.startTime)),
    endTime: dateToTimestamp(combine(data.startDate, data.endTime)),
    maxParticipants: { value: data.maxParticipants },
    location: { value: data.location },
    url: { value: data.url },
    color: { value: data.color },
    description: { value: data.description },
    cost: { value: data.cost },
    title: { value: data.title },
    image: { value: data.image },
    disableActions: { value: data.disableActions },
    repeat: repeats
      ? {
          endAfter: data.endRepeatDate && dateToTimestamp(data.endRepeatDate),
          frequency: data.frequency,
        }
      : undefined,
  });
  return response;
}

async function updateResource(data: FormFields, id: string | undefined) {
  const response = grpc.resource.updateResource({
    id: id || '',
    title: { value: data.orgName },
  });
  return response;
}

async function deleteAvailability(availabilityId: string) {
  const response = grpc.availabilities.deleteAvailability({
    id: availabilityId,
  });
  return response;
}

interface Props extends AvailabilityDTO.AsObject {
  onUpdate: () => void;
}

// eslint-disable-next-line max-lines-per-function
export function EditEventForm(props: Props) {
  const { register, handleSubmit, errors, setValue } = useForm<FormFields>();
  const [repeats, setRepeats] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    setPropsAsData();
  }, []);

  async function setPropsAsData() {
    setValue('startDate', format(toDate(props.startTime), 'yyyy-MM-dd'));
    setValue('startTime', format(toDate(props.startTime), 'HH:mm'));
    setValue('endTime', format(toDate(props.endTime), 'HH:mm'));
    setValue('maxParticipants', props.maxParticipants);
    setValue('location', props.location?.value);
    setValue('url', props.url?.value);
    setValue('color', props.color?.value);
    setValue('description', props.description?.value);
    setValue('image', props.image?.value);
    setValue('title', props.title?.value);
    setValue('cost', props.cost?.value);
    setValue('frequency', props.repeat?.frequency);
    setValue('orgName', props.resource?.title);
    setValue('endRepeatDate', format(toDate(props.repeat?.endAfter), 'yyyy-MM-dd'));
    setValue('disableActions', props.disableActions);
  }

  const handleUpdate = async (data: FormFields) => {
    if (disabled) {
      setDisabled(false);
    } else {
      try {
        await updateResource(data, props.resource?.id);
        await updateAvailability(data, props.id, repeats);
        setError('');
        setDisabled(true);
        props.onUpdate();
      } catch (err) {
        setError('Error: ' + err.message);
      }
    }
  };

  const handleDelete = async () => {
    try {
      if (await confirm('Are you sure you wish to delete this event?')) {
        await deleteAvailability(props.id);
        setError('');
        props.onUpdate();
      }
    } catch (err) {
      setError('Error: ' + err.message);
    }
  };

  const handleCancel = () => {
    setPropsAsData();
    setDisabled(true);
  };

  function toggleRepeat() {
    setRepeats(!repeats);
  }

  return (
    <Layout>
      <Form>
        <EventForm
          errors={errors}
          repeats={repeats}
          toggleRepeat={toggleRepeat}
          register={register}
          disabled={disabled}
        />
        <Error>{error}</Error>
        <ButtonArea>
          {disabled ? (
            <ButtonDanger onClick={handleSubmit(handleDelete)}>Delete</ButtonDanger>
          ) : (
            <ButtonSecondary onClick={handleCancel}>Cancel</ButtonSecondary>
          )}
          <Button onClick={handleSubmit(handleUpdate)}>{disabled ? 'Edit' : 'Update'}</Button>
        </ButtonArea>
      </Form>
    </Layout>
  );
}
