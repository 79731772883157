import React from 'react';
import styled from 'styled-components';

export function NoEventsCard() {
  return (
    <Layout>
      <Text>No Events To Show.</Text>
    </Layout>
  );
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 1rem;
`;

const Text = styled.p`
  font-size: 1.2rem;
`;
