import React from 'react';
import styled from 'styled-components';
import { FieldError } from 'react-hook-form';

type FieldErrorProps = {
  fieldError?: FieldError;
  name: string;
};

export function FormFieldError(props: FieldErrorProps) {
  if (!props.fieldError) {
    return null;
  }

  return (
    <ToolTip>
      <ToolTipText>{errorText(props.name, props.fieldError)}</ToolTipText>
    </ToolTip>
  );
}

function errorText(fieldName: string, fieldError: FieldError) {
  if (fieldError.message) {
    return fieldError.message;
  }
  return `${fieldName} is ${fieldError.type}`;
}

const ToolTip = styled.div`
  position: relative;
  display: inline-block;
`;

const ToolTipText = styled.span`
  width: 120px;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  background-color: black;
`;
