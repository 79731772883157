import * as grpcWeb from 'grpc-web';
import { sharedUserService } from './authentication/UserService';

export function metadata() {
  const headers: grpcWeb.Metadata = {};
  if (sharedUserService.token) {
    headers['Authorization'] = `Bearer ${sharedUserService.token}`;
  }
  return headers;
}

export function registerDevTools(grpc: Record<string, unknown>) {
  // https://github.com/SafetyCulture/grpc-web-devtools
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const devtools = (window as any).__GRPCWEB_DEVTOOLS__;
  if (devtools) {
    devtools(Object.values(grpc));
  }
}
