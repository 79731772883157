import React from 'react';
import styled from 'styled-components';
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import { format } from 'date-fns';

interface Props {
  startTime: Timestamp.AsObject;
  endTime: Timestamp.AsObject;
  resourceTitle: string;
  bookingColor: string;
}

export function toDate(timestamp: Timestamp.AsObject): Date {
  const t = new Timestamp();
  t.setSeconds(timestamp.seconds);
  t.setNanos(timestamp.nanos);
  return t.toDate();
}

export function timeString(date: Date) {
  return format(date, 'hh:mm a');
}

export function dateString(date: Date) {
  return format(date, 'dd MMMM');
}

export function AvailabilityCardTopBar(props: Props) {
  const date = toDate(props.startTime);
  const endDate = toDate(props.endTime);

  return (
    <TopBar style={{ backgroundColor: props.bookingColor }}>
      <TopBarLeft>
        {timeString(date)} - {timeString(endDate)} | {dateString(date)}
      </TopBarLeft>
      <TopBarRight>
        <Pill style={{ color: props.bookingColor }} disabled>
          {props.resourceTitle}
        </Pill>
      </TopBarRight>
    </TopBar>
  );
}

const TopBar = styled.div`
  display: flex;
  border-radius: 5px 5px 0px 0px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  padding: 0.5rem 0.7rem;
  align-items: center;
`;

const Pill = styled.button`
  background-color: white;
  border: none;
  padding: 1px 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  border-radius: 999px;
  text-transform: uppercase;
  padding: 0.25rem 0.5rem;
  margin: 0;
  line-height: 1;
`;

const TopBarLeft = styled.p`
  margin-top: 5px;
  margin-bottom: 5px;
  color: white;
  text-transform: uppercase;
  line-height: 1;
  margin: 0;
`;

const TopBarRight = styled.div`
  margin-left: auto;
  margin-right: 0px;
`;
