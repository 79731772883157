// Generated by protoc-gen-grpc-ts-web. DO NOT EDIT!
/* eslint-disable */
/* tslint:disable */

import * as jspb from 'google-protobuf';
import * as grpcWeb from 'grpc-web';

export class ProjectsService {

	private client_ = new grpcWeb.GrpcWebClientBase({
		format: 'text',
	});

	private methodInfoGetProject = new grpcWeb.MethodDescriptor<GetProjectReq, GetProjectRes>(
		"GetProject",
		null,
		GetProjectReq,
		GetProjectRes,
		(req: GetProjectReq) => req.serializeBinary(),
		GetProjectRes.deserializeBinary
	);

	private methodInfoListProjects = new grpcWeb.MethodDescriptor<ListProjectsReq, ListProjectsRes>(
		"ListProjects",
		null,
		ListProjectsReq,
		ListProjectsRes,
		(req: ListProjectsReq) => req.serializeBinary(),
		ListProjectsRes.deserializeBinary
	);

	private methodInfoCreateProject = new grpcWeb.MethodDescriptor<CreateProjectReq, CreateProjectRes>(
		"CreateProject",
		null,
		CreateProjectReq,
		CreateProjectRes,
		(req: CreateProjectReq) => req.serializeBinary(),
		CreateProjectRes.deserializeBinary
	);

	private methodInfoDeleteProject = new grpcWeb.MethodDescriptor<DeleteProjectReq, DeleteProjectRes>(
		"DeleteProject",
		null,
		DeleteProjectReq,
		DeleteProjectRes,
		(req: DeleteProjectReq) => req.serializeBinary(),
		DeleteProjectRes.deserializeBinary
	);

	constructor(
		private hostname: string,
		private defaultMetadata?: () => grpcWeb.Metadata,
	) { }

	getProject(req: GetProjectReq.AsObject, metadata?: grpcWeb.Metadata): Promise<GetProjectRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = GetProjectReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.ProjectsService/GetProject',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoGetProject,
				(err: grpcWeb.Error, res: GetProjectRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	listProjects(req: ListProjectsReq.AsObject, metadata?: grpcWeb.Metadata): Promise<ListProjectsRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = ListProjectsReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.ProjectsService/ListProjects',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoListProjects,
				(err: grpcWeb.Error, res: ListProjectsRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	createProject(req: CreateProjectReq.AsObject, metadata?: grpcWeb.Metadata): Promise<CreateProjectRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = CreateProjectReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.ProjectsService/CreateProject',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoCreateProject,
				(err: grpcWeb.Error, res: CreateProjectRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	deleteProject(req: DeleteProjectReq.AsObject, metadata?: grpcWeb.Metadata): Promise<DeleteProjectRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = DeleteProjectReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.ProjectsService/DeleteProject',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoDeleteProject,
				(err: grpcWeb.Error, res: DeleteProjectRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

}




export declare namespace GetProjectReq {
	export type AsObject = {
		projectId: string,
	}
}

export class GetProjectReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, GetProjectReq.repeatedFields_, null);
	}


	getProjectId(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setProjectId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		GetProjectReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): GetProjectReq.AsObject {
		let f: any;
		return {
			projectId: this.getProjectId(),
		};
	}

	static serializeBinaryToWriter(message: GetProjectReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getProjectId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): GetProjectReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new GetProjectReq();
		return GetProjectReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: GetProjectReq, reader: jspb.BinaryReader): GetProjectReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setProjectId(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace GetProjectRes {
	export type AsObject = {
		project?: ProjectDTO.AsObject,
	}
}

export class GetProjectRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, GetProjectRes.repeatedFields_, null);
	}


	getProject(): ProjectDTO {
		return jspb.Message.getWrapperField(this, ProjectDTO, 1);
	}

	setProject(value?: ProjectDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		GetProjectRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): GetProjectRes.AsObject {
		let f: any;
		return {
			project: (f = this.getProject()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: GetProjectRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getProject();
		if (field1 != null) {
			writer.writeMessage(1, field1, ProjectDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): GetProjectRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new GetProjectRes();
		return GetProjectRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: GetProjectRes, reader: jspb.BinaryReader): GetProjectRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new ProjectDTO();
				reader.readMessage(field1, ProjectDTO.deserializeBinaryFromReader);
				message.setProject(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace ListProjectsReq {
	export type AsObject = {
	}
}

export class ListProjectsReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, ListProjectsReq.repeatedFields_, null);
	}


	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		ListProjectsReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): ListProjectsReq.AsObject {
		let f: any;
		return {
		};
	}

	static serializeBinaryToWriter(message: ListProjectsReq, writer: jspb.BinaryWriter): void {
	}

	static deserializeBinary(bytes: Uint8Array): ListProjectsReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new ListProjectsReq();
		return ListProjectsReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: ListProjectsReq, reader: jspb.BinaryReader): ListProjectsReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace ListProjectsRes {
	export type AsObject = {
		items: Array<ProjectDTO.AsObject>,
	}
}

export class ListProjectsRes extends jspb.Message {

	private static repeatedFields_ = [
		2,
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, ListProjectsRes.repeatedFields_, null);
	}


	getItems(): Array<ProjectDTO> {
		return jspb.Message.getRepeatedWrapperField(this, ProjectDTO, 2);
	}

	setItems(value: Array<ProjectDTO>): void {
		(jspb.Message as any).setRepeatedWrapperField(this, 2, value);
	}

	addItems(value?: ProjectDTO, index?: number): ProjectDTO {
		return jspb.Message.addToRepeatedWrapperField(this, 2, value, ProjectDTO, index);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		ListProjectsRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): ListProjectsRes.AsObject {
		let f: any;
		return {
			items: this.getItems().map((item) => item.toObject()),
		};
	}

	static serializeBinaryToWriter(message: ListProjectsRes, writer: jspb.BinaryWriter): void {
		const field2 = message.getItems();
		if (field2.length > 0) {
			writer.writeRepeatedMessage(2, field2, ProjectDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): ListProjectsRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new ListProjectsRes();
		return ListProjectsRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: ListProjectsRes, reader: jspb.BinaryReader): ListProjectsRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 2:
				const field2 = new ProjectDTO();
				reader.readMessage(field2, ProjectDTO.deserializeBinaryFromReader);
				message.addItems(field2);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateProjectReq {
	export type AsObject = {
		name: string,
	}
}

export class CreateProjectReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateProjectReq.repeatedFields_, null);
	}


	getName(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setName(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateProjectReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateProjectReq.AsObject {
		let f: any;
		return {
			name: this.getName(),
		};
	}

	static serializeBinaryToWriter(message: CreateProjectReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getName();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateProjectReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateProjectReq();
		return CreateProjectReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateProjectReq, reader: jspb.BinaryReader): CreateProjectReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setName(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateProjectRes {
	export type AsObject = {
		project?: ProjectDTO.AsObject,
	}
}

export class CreateProjectRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateProjectRes.repeatedFields_, null);
	}


	getProject(): ProjectDTO {
		return jspb.Message.getWrapperField(this, ProjectDTO, 1);
	}

	setProject(value?: ProjectDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateProjectRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateProjectRes.AsObject {
		let f: any;
		return {
			project: (f = this.getProject()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: CreateProjectRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getProject();
		if (field1 != null) {
			writer.writeMessage(1, field1, ProjectDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateProjectRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateProjectRes();
		return CreateProjectRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateProjectRes, reader: jspb.BinaryReader): CreateProjectRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new ProjectDTO();
				reader.readMessage(field1, ProjectDTO.deserializeBinaryFromReader);
				message.setProject(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace DeleteProjectReq {
	export type AsObject = {
		id: string,
	}
}

export class DeleteProjectReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, DeleteProjectReq.repeatedFields_, null);
	}


	getId(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		DeleteProjectReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): DeleteProjectReq.AsObject {
		let f: any;
		return {
			id: this.getId(),
		};
	}

	static serializeBinaryToWriter(message: DeleteProjectReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): DeleteProjectReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new DeleteProjectReq();
		return DeleteProjectReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: DeleteProjectReq, reader: jspb.BinaryReader): DeleteProjectReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setId(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace DeleteProjectRes {
	export type AsObject = {
	}
}

export class DeleteProjectRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, DeleteProjectRes.repeatedFields_, null);
	}


	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		DeleteProjectRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): DeleteProjectRes.AsObject {
		let f: any;
		return {
		};
	}

	static serializeBinaryToWriter(message: DeleteProjectRes, writer: jspb.BinaryWriter): void {
	}

	static deserializeBinary(bytes: Uint8Array): DeleteProjectRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new DeleteProjectRes();
		return DeleteProjectRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: DeleteProjectRes, reader: jspb.BinaryReader): DeleteProjectRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace ProjectDTO {
	export type AsObject = {
		id: string,
		name: string,
		tenantId: string,
	}
}

export class ProjectDTO extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, ProjectDTO.repeatedFields_, null);
	}


	getId(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	getName(): string {return jspb.Message.getFieldWithDefault(this, 2, "");
	}

	setName(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 2, value);
	}

	getTenantId(): string {return jspb.Message.getFieldWithDefault(this, 3, "");
	}

	setTenantId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 3, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		ProjectDTO.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): ProjectDTO.AsObject {
		let f: any;
		return {
			id: this.getId(),
			name: this.getName(),
			tenantId: this.getTenantId(),
		};
	}

	static serializeBinaryToWriter(message: ProjectDTO, writer: jspb.BinaryWriter): void {
		const field1 = message.getId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
		const field2 = message.getName();
		if (field2.length > 0) {
			writer.writeString(2, field2);
		}
		const field3 = message.getTenantId();
		if (field3.length > 0) {
			writer.writeString(3, field3);
		}
	}

	static deserializeBinary(bytes: Uint8Array): ProjectDTO {
		var reader = new jspb.BinaryReader(bytes);
		var message = new ProjectDTO();
		return ProjectDTO.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: ProjectDTO, reader: jspb.BinaryReader): ProjectDTO {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setId(field1);
				break;
			case 2:
				const field2 = reader.readString()
				message.setName(field2);
				break;
			case 3:
				const field3 = reader.readString()
				message.setTenantId(field3);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}


function GetProjectReqFromObject(obj: GetProjectReq.AsObject | undefined): GetProjectReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new GetProjectReq();
	message.setProjectId(obj.projectId);
	return message;
}

function GetProjectResFromObject(obj: GetProjectRes.AsObject | undefined): GetProjectRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new GetProjectRes();
	message.setProject(ProjectDTOFromObject(obj.project));
	return message;
}

function ProjectDTOFromObject(obj: ProjectDTO.AsObject | undefined): ProjectDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new ProjectDTO();
	message.setId(obj.id);
	message.setName(obj.name);
	message.setTenantId(obj.tenantId);
	return message;
}

function ListProjectsReqFromObject(obj: ListProjectsReq.AsObject | undefined): ListProjectsReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new ListProjectsReq();
	return message;
}

function ListProjectsResFromObject(obj: ListProjectsRes.AsObject | undefined): ListProjectsRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new ListProjectsRes();
	(obj.items || [])
		.map((item) => ProjectDTOFromObject(item))
		.forEach((item) => message.addItems(item));
	return message;
}

function CreateProjectReqFromObject(obj: CreateProjectReq.AsObject | undefined): CreateProjectReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateProjectReq();
	message.setName(obj.name);
	return message;
}

function CreateProjectResFromObject(obj: CreateProjectRes.AsObject | undefined): CreateProjectRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateProjectRes();
	message.setProject(ProjectDTOFromObject(obj.project));
	return message;
}

function DeleteProjectReqFromObject(obj: DeleteProjectReq.AsObject | undefined): DeleteProjectReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new DeleteProjectReq();
	message.setId(obj.id);
	return message;
}

function DeleteProjectResFromObject(obj: DeleteProjectRes.AsObject | undefined): DeleteProjectRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new DeleteProjectRes();
	return message;
}

