import { makeAutoObservable, observable } from 'mobx';
import { UserManager } from 'oidc-client';
import { environment } from '../utils/Environment';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import LocalStorage from './LocalStorage';

const config = {
  authority: environment.oidc.authority,
  client_id: environment.oidc.clientId,
  response_type: 'token',
  scope: 'openid api',
};

interface TokenPayload extends JwtPayload {
  role: Role | Role[];
}

export enum Role {
  SuperAdmin = 'SuperAdmin',
  Admin = 'Admin',
  TenantApplication = 'TenantApplication',
  AnonymousWidget = 'AnonymousWidget',
  TenantUser = 'TenantUser',
  AdminWidget = 'AdminWidget',
}

class UserService {
  @observable
  localStorage: LocalStorage = new LocalStorage();
  token?: string;

  constructor() {
    this.token = this.localStorage.getItem('token') || undefined;
    makeAutoObservable(this);
  }

  async signIn(username: string, password: string) {
    const oidc = new UserManager(config);
    const tokenEndpoint = await oidc.metadataService.getTokenEndpoint();

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const res = await fetch(tokenEndpoint!, {
      method: 'POST',
      body: new URLSearchParams({
        grant_type: 'password',
        client_id: config.client_id,
        scope: config.scope,
        username,
        password,
      }),
    });

    const json = await res.json();
    if (json.error) {
      throw new Error('invalid username or password');
    }

    this.token = json.access_token as string;
    this.localStorage.setItem('token', this.token);
  }

  signInToken(token: string) {
    this.token = token;
    this.localStorage.setItem('token', this.token);
  }

  signOut() {
    this.localStorage.removeItem('token');
    this.token = undefined;
  }

  roles() {
    const payload = this.tokenPayload();
    if (!payload) {
      return [];
    }
    return typeof payload.role === 'string' ? [payload.role] : payload?.role;
  }

  subject() {
    return this.tokenPayload()?.sub;
  }

  private tokenPayload() {
    if (!this.token) {
      return undefined;
    }
    return jwtDecode<TokenPayload>(this.token);
  }
}

export const sharedUserService = new UserService();
