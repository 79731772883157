// Generated by protoc-gen-grpc-ts-web. DO NOT EDIT!
/* eslint-disable */
/* tslint:disable */

import * as jspb from 'google-protobuf';
import * as grpcWeb from 'grpc-web';

import * as googleProtobufTimestamp from 'google-protobuf/google/protobuf/timestamp_pb';
import * as googleProtobufWrappers from 'google-protobuf/google/protobuf/wrappers_pb';
import * as core from './Core_pb';
import * as availabilities from './Availabilities_pb';

export class WidgetsService {

	private client_ = new grpcWeb.GrpcWebClientBase({
		format: 'text',
	});

	private methodInfoCreateEventsListWidget = new grpcWeb.MethodDescriptor<CreateEventsListWidgetReq, CreateEventsListWidgetRes>(
		"CreateEventsListWidget",
		null,
		CreateEventsListWidgetReq,
		CreateEventsListWidgetRes,
		(req: CreateEventsListWidgetReq) => req.serializeBinary(),
		CreateEventsListWidgetRes.deserializeBinary
	);

	private methodInfoCreateEventWidget = new grpcWeb.MethodDescriptor<CreateEventWidgetReq, CreateEventWidgetRes>(
		"CreateEventWidget",
		null,
		CreateEventWidgetReq,
		CreateEventWidgetRes,
		(req: CreateEventWidgetReq) => req.serializeBinary(),
		CreateEventWidgetRes.deserializeBinary
	);

	private methodInfoCreateEventsManagementWidget = new grpcWeb.MethodDescriptor<CreateEventsManagementWidgetReq, CreateEventsManagementWidgetRes>(
		"CreateEventsManagementWidget",
		null,
		CreateEventsManagementWidgetReq,
		CreateEventsManagementWidgetRes,
		(req: CreateEventsManagementWidgetReq) => req.serializeBinary(),
		CreateEventsManagementWidgetRes.deserializeBinary
	);

	constructor(
		private hostname: string,
		private defaultMetadata?: () => grpcWeb.Metadata,
	) { }

	createEventsListWidget(req: CreateEventsListWidgetReq.AsObject, metadata?: grpcWeb.Metadata): Promise<CreateEventsListWidgetRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = CreateEventsListWidgetReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.WidgetsService/CreateEventsListWidget',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoCreateEventsListWidget,
				(err: grpcWeb.Error, res: CreateEventsListWidgetRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	createEventWidget(req: CreateEventWidgetReq.AsObject, metadata?: grpcWeb.Metadata): Promise<CreateEventWidgetRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = CreateEventWidgetReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.WidgetsService/CreateEventWidget',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoCreateEventWidget,
				(err: grpcWeb.Error, res: CreateEventWidgetRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	createEventsManagementWidget(req: CreateEventsManagementWidgetReq.AsObject, metadata?: grpcWeb.Metadata): Promise<CreateEventsManagementWidgetRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = CreateEventsManagementWidgetReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.WidgetsService/CreateEventsManagementWidget',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoCreateEventsManagementWidget,
				(err: grpcWeb.Error, res: CreateEventsManagementWidgetRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

}

export enum WidgetTypeDTO {
	WIDGET_TYPE_UNSPECIFIED = 0,
	WIDGET_TYPE_EVENTS_LIST = 1,
	WIDGET_TYPE_CREATE_EVENT = 2,
	WIDGET_TYPE_MANAGE_EVENTS_LIST = 3,
}



export declare namespace CreateEventsListWidgetReq {
	export type AsObject = {
		parameters?: EventsListWidgetDTO.AsObject,
		authTags?: core.AuthorizationTagsDTO.AsObject,
		externalUserReference?: googleProtobufWrappers.StringValue.AsObject,
	}
}

export class CreateEventsListWidgetReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateEventsListWidgetReq.repeatedFields_, null);
	}


	getParameters(): EventsListWidgetDTO {
		return jspb.Message.getWrapperField(this, EventsListWidgetDTO, 1);
	}

	setParameters(value?: EventsListWidgetDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	getAuthTags(): core.AuthorizationTagsDTO {
		return jspb.Message.getWrapperField(this, core.AuthorizationTagsDTO, 2);
	}

	setAuthTags(value?: core.AuthorizationTagsDTO): void {
		(jspb.Message as any).setWrapperField(this, 2, value);
	}

	getExternalUserReference(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 3);
	}

	setExternalUserReference(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 3, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateEventsListWidgetReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateEventsListWidgetReq.AsObject {
		let f: any;
		return {
			parameters: (f = this.getParameters()) && f.toObject(),
			authTags: (f = this.getAuthTags()) && f.toObject(),
			externalUserReference: (f = this.getExternalUserReference()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: CreateEventsListWidgetReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getParameters();
		if (field1 != null) {
			writer.writeMessage(1, field1, EventsListWidgetDTO.serializeBinaryToWriter);
		}
		const field2 = message.getAuthTags();
		if (field2 != null) {
			writer.writeMessage(2, field2, core.AuthorizationTagsDTO.serializeBinaryToWriter);
		}
		const field3 = message.getExternalUserReference();
		if (field3 != null) {
			writer.writeMessage(3, field3, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateEventsListWidgetReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateEventsListWidgetReq();
		return CreateEventsListWidgetReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateEventsListWidgetReq, reader: jspb.BinaryReader): CreateEventsListWidgetReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new EventsListWidgetDTO();
				reader.readMessage(field1, EventsListWidgetDTO.deserializeBinaryFromReader);
				message.setParameters(field1);
				break;
			case 2:
				const field2 = new core.AuthorizationTagsDTO();
				reader.readMessage(field2, core.AuthorizationTagsDTO.deserializeBinaryFromReader);
				message.setAuthTags(field2);
				break;
			case 3:
				const field3 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field3, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setExternalUserReference(field3);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateEventsListWidgetRes {
	export type AsObject = {
		url: string,
	}
}

export class CreateEventsListWidgetRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateEventsListWidgetRes.repeatedFields_, null);
	}


	getUrl(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setUrl(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateEventsListWidgetRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateEventsListWidgetRes.AsObject {
		let f: any;
		return {
			url: this.getUrl(),
		};
	}

	static serializeBinaryToWriter(message: CreateEventsListWidgetRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getUrl();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateEventsListWidgetRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateEventsListWidgetRes();
		return CreateEventsListWidgetRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateEventsListWidgetRes, reader: jspb.BinaryReader): CreateEventsListWidgetRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setUrl(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace EventsListWidgetDTO {
	export type AsObject = {
		projectId?: googleProtobufWrappers.StringValue.AsObject,
		since?: googleProtobufTimestamp.Timestamp.AsObject,
		until?: googleProtobufTimestamp.Timestamp.AsObject,
		availabilityFilter: availabilities.AvailabilityFilterDTO,
		ownerEmail?: googleProtobufWrappers.StringValue.AsObject,
		ownerFirstName?: googleProtobufWrappers.StringValue.AsObject,
		ownerLastName?: googleProtobufWrappers.StringValue.AsObject,
	}
}

export class EventsListWidgetDTO extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, EventsListWidgetDTO.repeatedFields_, null);
	}


	getProjectId(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 1);
	}

	setProjectId(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	getSince(): googleProtobufTimestamp.Timestamp {
		return jspb.Message.getWrapperField(this, googleProtobufTimestamp.Timestamp, 2);
	}

	setSince(value?: googleProtobufTimestamp.Timestamp): void {
		(jspb.Message as any).setWrapperField(this, 2, value);
	}

	getUntil(): googleProtobufTimestamp.Timestamp {
		return jspb.Message.getWrapperField(this, googleProtobufTimestamp.Timestamp, 3);
	}

	setUntil(value?: googleProtobufTimestamp.Timestamp): void {
		(jspb.Message as any).setWrapperField(this, 3, value);
	}

	getAvailabilityFilter(): availabilities.AvailabilityFilterDTO {return jspb.Message.getFieldWithDefault(this, 4, 0);
	}

	setAvailabilityFilter(value: availabilities.AvailabilityFilterDTO): void {
		(jspb.Message as any).setProto3EnumField(this, 4, value);
	}

	getOwnerEmail(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 5);
	}

	setOwnerEmail(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 5, value);
	}

	getOwnerFirstName(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 6);
	}

	setOwnerFirstName(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 6, value);
	}

	getOwnerLastName(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 7);
	}

	setOwnerLastName(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 7, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		EventsListWidgetDTO.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): EventsListWidgetDTO.AsObject {
		let f: any;
		return {
			projectId: (f = this.getProjectId()) && f.toObject(),
			since: (f = this.getSince()) && f.toObject(),
			until: (f = this.getUntil()) && f.toObject(),
			availabilityFilter: this.getAvailabilityFilter(),
			ownerEmail: (f = this.getOwnerEmail()) && f.toObject(),
			ownerFirstName: (f = this.getOwnerFirstName()) && f.toObject(),
			ownerLastName: (f = this.getOwnerLastName()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: EventsListWidgetDTO, writer: jspb.BinaryWriter): void {
		const field1 = message.getProjectId();
		if (field1 != null) {
			writer.writeMessage(1, field1, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field2 = message.getSince();
		if (field2 != null) {
			writer.writeMessage(2, field2, googleProtobufTimestamp.Timestamp.serializeBinaryToWriter);
		}
		const field3 = message.getUntil();
		if (field3 != null) {
			writer.writeMessage(3, field3, googleProtobufTimestamp.Timestamp.serializeBinaryToWriter);
		}
		const field4 = message.getAvailabilityFilter();
		if (field4 != 0) {
			writer.writeEnum(4, field4);
		}
		const field5 = message.getOwnerEmail();
		if (field5 != null) {
			writer.writeMessage(5, field5, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field6 = message.getOwnerFirstName();
		if (field6 != null) {
			writer.writeMessage(6, field6, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field7 = message.getOwnerLastName();
		if (field7 != null) {
			writer.writeMessage(7, field7, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): EventsListWidgetDTO {
		var reader = new jspb.BinaryReader(bytes);
		var message = new EventsListWidgetDTO();
		return EventsListWidgetDTO.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: EventsListWidgetDTO, reader: jspb.BinaryReader): EventsListWidgetDTO {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field1, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setProjectId(field1);
				break;
			case 2:
				const field2 = new googleProtobufTimestamp.Timestamp();
				reader.readMessage(field2, googleProtobufTimestamp.Timestamp.deserializeBinaryFromReader);
				message.setSince(field2);
				break;
			case 3:
				const field3 = new googleProtobufTimestamp.Timestamp();
				reader.readMessage(field3, googleProtobufTimestamp.Timestamp.deserializeBinaryFromReader);
				message.setUntil(field3);
				break;
			case 4:
				const field4 = reader.readEnum()
				message.setAvailabilityFilter(field4);
				break;
			case 5:
				const field5 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field5, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setOwnerEmail(field5);
				break;
			case 6:
				const field6 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field6, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setOwnerFirstName(field6);
				break;
			case 7:
				const field7 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field7, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setOwnerLastName(field7);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateEventWidgetReq {
	export type AsObject = {
		parameters?: CreateEventWidgetDTO.AsObject,
		authTags?: core.AuthorizationTagsDTO.AsObject,
	}
}

export class CreateEventWidgetReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateEventWidgetReq.repeatedFields_, null);
	}


	getParameters(): CreateEventWidgetDTO {
		return jspb.Message.getWrapperField(this, CreateEventWidgetDTO, 1);
	}

	setParameters(value?: CreateEventWidgetDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	getAuthTags(): core.AuthorizationTagsDTO {
		return jspb.Message.getWrapperField(this, core.AuthorizationTagsDTO, 2);
	}

	setAuthTags(value?: core.AuthorizationTagsDTO): void {
		(jspb.Message as any).setWrapperField(this, 2, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateEventWidgetReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateEventWidgetReq.AsObject {
		let f: any;
		return {
			parameters: (f = this.getParameters()) && f.toObject(),
			authTags: (f = this.getAuthTags()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: CreateEventWidgetReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getParameters();
		if (field1 != null) {
			writer.writeMessage(1, field1, CreateEventWidgetDTO.serializeBinaryToWriter);
		}
		const field2 = message.getAuthTags();
		if (field2 != null) {
			writer.writeMessage(2, field2, core.AuthorizationTagsDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateEventWidgetReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateEventWidgetReq();
		return CreateEventWidgetReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateEventWidgetReq, reader: jspb.BinaryReader): CreateEventWidgetReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new CreateEventWidgetDTO();
				reader.readMessage(field1, CreateEventWidgetDTO.deserializeBinaryFromReader);
				message.setParameters(field1);
				break;
			case 2:
				const field2 = new core.AuthorizationTagsDTO();
				reader.readMessage(field2, core.AuthorizationTagsDTO.deserializeBinaryFromReader);
				message.setAuthTags(field2);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateEventWidgetRes {
	export type AsObject = {
		url: string,
	}
}

export class CreateEventWidgetRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateEventWidgetRes.repeatedFields_, null);
	}


	getUrl(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setUrl(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateEventWidgetRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateEventWidgetRes.AsObject {
		let f: any;
		return {
			url: this.getUrl(),
		};
	}

	static serializeBinaryToWriter(message: CreateEventWidgetRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getUrl();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateEventWidgetRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateEventWidgetRes();
		return CreateEventWidgetRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateEventWidgetRes, reader: jspb.BinaryReader): CreateEventWidgetRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setUrl(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateEventWidgetDTO {
	export type AsObject = {
		projectId?: googleProtobufWrappers.StringValue.AsObject,
	}
}

export class CreateEventWidgetDTO extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateEventWidgetDTO.repeatedFields_, null);
	}


	getProjectId(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 1);
	}

	setProjectId(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateEventWidgetDTO.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateEventWidgetDTO.AsObject {
		let f: any;
		return {
			projectId: (f = this.getProjectId()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: CreateEventWidgetDTO, writer: jspb.BinaryWriter): void {
		const field1 = message.getProjectId();
		if (field1 != null) {
			writer.writeMessage(1, field1, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateEventWidgetDTO {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateEventWidgetDTO();
		return CreateEventWidgetDTO.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateEventWidgetDTO, reader: jspb.BinaryReader): CreateEventWidgetDTO {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field1, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setProjectId(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateEventsManagementWidgetReq {
	export type AsObject = {
		parameters?: CreateEventsManagementWidgetDTO.AsObject,
		authTags?: core.AuthorizationTagsDTO.AsObject,
	}
}

export class CreateEventsManagementWidgetReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateEventsManagementWidgetReq.repeatedFields_, null);
	}


	getParameters(): CreateEventsManagementWidgetDTO {
		return jspb.Message.getWrapperField(this, CreateEventsManagementWidgetDTO, 1);
	}

	setParameters(value?: CreateEventsManagementWidgetDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	getAuthTags(): core.AuthorizationTagsDTO {
		return jspb.Message.getWrapperField(this, core.AuthorizationTagsDTO, 2);
	}

	setAuthTags(value?: core.AuthorizationTagsDTO): void {
		(jspb.Message as any).setWrapperField(this, 2, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateEventsManagementWidgetReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateEventsManagementWidgetReq.AsObject {
		let f: any;
		return {
			parameters: (f = this.getParameters()) && f.toObject(),
			authTags: (f = this.getAuthTags()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: CreateEventsManagementWidgetReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getParameters();
		if (field1 != null) {
			writer.writeMessage(1, field1, CreateEventsManagementWidgetDTO.serializeBinaryToWriter);
		}
		const field2 = message.getAuthTags();
		if (field2 != null) {
			writer.writeMessage(2, field2, core.AuthorizationTagsDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateEventsManagementWidgetReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateEventsManagementWidgetReq();
		return CreateEventsManagementWidgetReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateEventsManagementWidgetReq, reader: jspb.BinaryReader): CreateEventsManagementWidgetReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new CreateEventsManagementWidgetDTO();
				reader.readMessage(field1, CreateEventsManagementWidgetDTO.deserializeBinaryFromReader);
				message.setParameters(field1);
				break;
			case 2:
				const field2 = new core.AuthorizationTagsDTO();
				reader.readMessage(field2, core.AuthorizationTagsDTO.deserializeBinaryFromReader);
				message.setAuthTags(field2);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateEventsManagementWidgetDTO {
	export type AsObject = {
		projectId?: googleProtobufWrappers.StringValue.AsObject,
		since?: googleProtobufTimestamp.Timestamp.AsObject,
		until?: googleProtobufTimestamp.Timestamp.AsObject,
	}
}

export class CreateEventsManagementWidgetDTO extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateEventsManagementWidgetDTO.repeatedFields_, null);
	}


	getProjectId(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 1);
	}

	setProjectId(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	getSince(): googleProtobufTimestamp.Timestamp {
		return jspb.Message.getWrapperField(this, googleProtobufTimestamp.Timestamp, 2);
	}

	setSince(value?: googleProtobufTimestamp.Timestamp): void {
		(jspb.Message as any).setWrapperField(this, 2, value);
	}

	getUntil(): googleProtobufTimestamp.Timestamp {
		return jspb.Message.getWrapperField(this, googleProtobufTimestamp.Timestamp, 3);
	}

	setUntil(value?: googleProtobufTimestamp.Timestamp): void {
		(jspb.Message as any).setWrapperField(this, 3, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateEventsManagementWidgetDTO.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateEventsManagementWidgetDTO.AsObject {
		let f: any;
		return {
			projectId: (f = this.getProjectId()) && f.toObject(),
			since: (f = this.getSince()) && f.toObject(),
			until: (f = this.getUntil()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: CreateEventsManagementWidgetDTO, writer: jspb.BinaryWriter): void {
		const field1 = message.getProjectId();
		if (field1 != null) {
			writer.writeMessage(1, field1, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field2 = message.getSince();
		if (field2 != null) {
			writer.writeMessage(2, field2, googleProtobufTimestamp.Timestamp.serializeBinaryToWriter);
		}
		const field3 = message.getUntil();
		if (field3 != null) {
			writer.writeMessage(3, field3, googleProtobufTimestamp.Timestamp.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateEventsManagementWidgetDTO {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateEventsManagementWidgetDTO();
		return CreateEventsManagementWidgetDTO.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateEventsManagementWidgetDTO, reader: jspb.BinaryReader): CreateEventsManagementWidgetDTO {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field1, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setProjectId(field1);
				break;
			case 2:
				const field2 = new googleProtobufTimestamp.Timestamp();
				reader.readMessage(field2, googleProtobufTimestamp.Timestamp.deserializeBinaryFromReader);
				message.setSince(field2);
				break;
			case 3:
				const field3 = new googleProtobufTimestamp.Timestamp();
				reader.readMessage(field3, googleProtobufTimestamp.Timestamp.deserializeBinaryFromReader);
				message.setUntil(field3);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateEventsManagementWidgetRes {
	export type AsObject = {
		url: string,
	}
}

export class CreateEventsManagementWidgetRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateEventsManagementWidgetRes.repeatedFields_, null);
	}


	getUrl(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setUrl(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateEventsManagementWidgetRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateEventsManagementWidgetRes.AsObject {
		let f: any;
		return {
			url: this.getUrl(),
		};
	}

	static serializeBinaryToWriter(message: CreateEventsManagementWidgetRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getUrl();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateEventsManagementWidgetRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateEventsManagementWidgetRes();
		return CreateEventsManagementWidgetRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateEventsManagementWidgetRes, reader: jspb.BinaryReader): CreateEventsManagementWidgetRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setUrl(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}


function CreateEventsListWidgetReqFromObject(obj: CreateEventsListWidgetReq.AsObject | undefined): CreateEventsListWidgetReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateEventsListWidgetReq();
	message.setParameters(EventsListWidgetDTOFromObject(obj.parameters));
	message.setAuthTags(AuthorizationTagsDTOFromObject(obj.authTags));
	message.setExternalUserReference(StringValueFromObject(obj.externalUserReference));
	return message;
}

function EventsListWidgetDTOFromObject(obj: EventsListWidgetDTO.AsObject | undefined): EventsListWidgetDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new EventsListWidgetDTO();
	message.setProjectId(StringValueFromObject(obj.projectId));
	message.setSince(TimestampFromObject(obj.since));
	message.setUntil(TimestampFromObject(obj.until));
	message.setAvailabilityFilter(obj.availabilityFilter);
	message.setOwnerEmail(StringValueFromObject(obj.ownerEmail));
	message.setOwnerFirstName(StringValueFromObject(obj.ownerFirstName));
	message.setOwnerLastName(StringValueFromObject(obj.ownerLastName));
	return message;
}

function StringValueFromObject(obj: googleProtobufWrappers.StringValue.AsObject | undefined): googleProtobufWrappers.StringValue | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new googleProtobufWrappers.StringValue();
	message.setValue(obj.value);
	return message;
}

function TimestampFromObject(obj: googleProtobufTimestamp.Timestamp.AsObject | undefined): googleProtobufTimestamp.Timestamp | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new googleProtobufTimestamp.Timestamp();
	message.setSeconds(obj.seconds);
	message.setNanos(obj.nanos);
	return message;
}

function AuthorizationTagsDTOFromObject(obj: core.AuthorizationTagsDTO.AsObject | undefined): core.AuthorizationTagsDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new core.AuthorizationTagsDTO();
	(obj.tagsMap || [])
		.forEach((entry) => message.getTagsMap().set(entry[0], entry[1]));
	return message;
}

function CreateEventsListWidgetResFromObject(obj: CreateEventsListWidgetRes.AsObject | undefined): CreateEventsListWidgetRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateEventsListWidgetRes();
	message.setUrl(obj.url);
	return message;
}

function CreateEventWidgetReqFromObject(obj: CreateEventWidgetReq.AsObject | undefined): CreateEventWidgetReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateEventWidgetReq();
	message.setParameters(CreateEventWidgetDTOFromObject(obj.parameters));
	message.setAuthTags(AuthorizationTagsDTOFromObject(obj.authTags));
	return message;
}

function CreateEventWidgetDTOFromObject(obj: CreateEventWidgetDTO.AsObject | undefined): CreateEventWidgetDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateEventWidgetDTO();
	message.setProjectId(StringValueFromObject(obj.projectId));
	return message;
}

function CreateEventWidgetResFromObject(obj: CreateEventWidgetRes.AsObject | undefined): CreateEventWidgetRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateEventWidgetRes();
	message.setUrl(obj.url);
	return message;
}

function CreateEventsManagementWidgetReqFromObject(obj: CreateEventsManagementWidgetReq.AsObject | undefined): CreateEventsManagementWidgetReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateEventsManagementWidgetReq();
	message.setParameters(CreateEventsManagementWidgetDTOFromObject(obj.parameters));
	message.setAuthTags(AuthorizationTagsDTOFromObject(obj.authTags));
	return message;
}

function CreateEventsManagementWidgetDTOFromObject(obj: CreateEventsManagementWidgetDTO.AsObject | undefined): CreateEventsManagementWidgetDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateEventsManagementWidgetDTO();
	message.setProjectId(StringValueFromObject(obj.projectId));
	message.setSince(TimestampFromObject(obj.since));
	message.setUntil(TimestampFromObject(obj.until));
	return message;
}

function CreateEventsManagementWidgetResFromObject(obj: CreateEventsManagementWidgetRes.AsObject | undefined): CreateEventsManagementWidgetRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateEventsManagementWidgetRes();
	message.setUrl(obj.url);
	return message;
}

