export default class LocalStorage {
  localStorageActive: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  storage: Map<string, any>;

  constructor() {
    this.localStorageActive = this.isLocalStorageActive();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.storage = new Map<string, any>();
  }

  getItem(key: string) {
    if (this.localStorageActive) {
      return localStorage.getItem(key);
    } else {
      return this.storage.get(key);
    }
  }

  setItem(key: string, value: string) {
    if (this.localStorageActive) {
      localStorage.setItem(key, value);
    } else {
      this.storage.set(key, value);
    }
  }

  removeItem(key: string) {
    if (this.localStorageActive) {
      localStorage.removeItem(key);
    } else {
      this.storage.delete(key);
    }
  }

  private isLocalStorageActive() {
    // This method checks if the browser will allow the page
    // to use localstorage.
    // Not all browsers will, for example Brave will block
    // the use of localstorage when the page is loaded in an
    // iframe.
    try {
      localStorage.setItem('test', 'test');
      localStorage.removeItem('test');
      return true;
    } catch (e) {
      return false;
    }
  }
}
