import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import grpc from '../../grpc';
import { EventForm, FormFields } from './EventForm';
import { Layout, Form, Button, Error } from '../../kit/Form';
import { combine, dateToTimestamp } from '../../grpc-lib';
import { CreateEventWidgetDTO } from '../../../Lib/sdk/Widgets_pb';
import styled from 'styled-components';

async function createAvailability(data: FormFields, resource_id: string, repeats: boolean) {
  const response = grpc.availabilities.createAvailability({
    resourceId: resource_id,
    startTime: dateToTimestamp(combine(data.startDate, data.startTime)),
    endTime: dateToTimestamp(combine(data.startDate, data.endTime)),
    maxParticipants: { value: data.maxParticipants },
    location: { value: data.location },
    url: { value: data.url },
    color: { value: data.color },
    description: { value: data.description },
    cost: { value: data.cost },
    title: { value: data.title },
    image: { value: data.image },
    disableActions: { value: data.disableActions },
    repeat: repeats
      ? {
          endAfter: data.endRepeatDate && dateToTimestamp(data.endRepeatDate),
          frequency: data.frequency,
        }
      : undefined,
  });
  return response;
}

async function createResource(data: FormFields, projectId: string | undefined) {
  const response = await grpc.resource.createResource({
    title: data.orgName,
    projectId: projectId || '',
    description: { value: data.description },
  });
  return response;
}

interface Props extends CreateEventWidgetDTO.AsObject {
  toggleSuccess: () => void;
}

export function CreateEventForm(props: Props) {
  const { register, handleSubmit, reset, errors } = useForm<FormFields>();
  const [repeats, setRepeats] = useState(false);
  const [error, setError] = useState('');

  function toggleRepeat() {
    setRepeats(!repeats);
  }

  const onSubmit = (data: FormFields) => {
    const resourceResponse = createResource(data, props.projectId?.value);
    resourceResponse.catch((err) => setError('Error: ' + err.message));
    resourceResponse.then((resource) => {
      const availabilityResponse =
        resource.resource && createAvailability(data, resource.resource?.id, repeats);
      availabilityResponse?.catch((err) => setError('Error: ' + err.message));
      availabilityResponse?.then(() => {
        setError('');
        reset();
        props.toggleSuccess();
      });
    });
  };

  return (
    <Layout>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <EventForm
          errors={errors}
          repeats={repeats}
          toggleRepeat={toggleRepeat}
          register={register}
        />
        <Error>{error}</Error>
        <ButtonLayout>
          <Button type="submit">Create</Button>
        </ButtonLayout>
      </Form>
    </Layout>
  );
}

const ButtonLayout = styled.div`
  text-align: center;
`;
