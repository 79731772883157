import React, { useState } from 'react';
import styled from 'styled-components';
import { AvailabilityDTO } from '../../Lib/sdk/Availabilities_pb';
import { BookingDTO } from '../../Lib/sdk/Bookings_pb';
import { CreateEventWidgetDTO } from '../../Lib/sdk/Widgets_pb';
import { SuccessPage } from '../components/EventForm/SuccessPage';
import { CreateEventForm } from '../components/EventForm/CreateEventForm';

export type AvailibilityBooking = AvailabilityDTO.AsObject & {
  booking: BookingDTO.AsObject | undefined;
};

export function CreateEventWidget(props: CreateEventWidgetDTO.AsObject) {
  const [success, setSuccess] = useState(false);

  function toggleSuccess(value: boolean) {
    setSuccess(value);
  }

  return (
    <Layout>
      {success ? (
        <SuccessPage
          handleNewBooking={() => {
            toggleSuccess(false);
          }}
        />
      ) : (
        <CreateEventForm
          {...props}
          toggleSuccess={() => {
            toggleSuccess(true);
          }}
        />
      )}
    </Layout>
  );
}

const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;
