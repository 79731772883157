import React, { useState } from 'react';
import styled from 'styled-components';
import { AvailabilityCardTopBar } from './AvailabilityCardTopBar';
import { AvailabilityCardContent } from './AvailabilityCardContent';
import { AvailabilityDTO } from '../../../Lib/sdk/Availabilities_pb';
import { BookingDTO } from '../../../Lib/sdk/Bookings_pb';
import { primary } from '../../kit/Colors';
import { EditEventForm } from '../EventForm/EditEventForm';
import { BookingsList } from '../BookingList/BookingsList';
import { ButtonSecondary } from '../../kit/Form';
import useOnceAsync from '../../../Lib/hooks/UseOnceAsync';
import grpc from '../../grpc';

export interface CardProps {
  disableActions: boolean;
  availability: AvailabilityDTO.AsObject;
  booking?: BookingDTO.AsObject;
  formExpanded: boolean;
  setFormExpanded: React.Dispatch<React.SetStateAction<string>>;
  onUpdate: () => void;
}

export function ManageCard(props: CardProps) {
  const [showBookings, setShowBookings] = useState(false);

  const handleToggle = (value: boolean) => {
    if (value) {
      props.setFormExpanded(props.availability.id);
    } else {
      props.setFormExpanded('');
    }
  };

  const bookings = useOnceAsync(async () => {
    return await grpc.bookings.listBookings({
      projectId: { value: props.availability.project?.id ?? '' },
      availabilityId: { value: props.availability.id ?? '' },
    });
  });

  return (
    <Layout>
      <AvailabilityCardTopBar
        startTime={props.availability.startTime!}
        endTime={props.availability.endTime!}
        resourceTitle={props.availability.resource?.title ?? ''}
        bookingColor={props.availability.color?.value ?? primary}
      />
      <AvailabilityCardContent
        imageUrl={
          (props.availability.image?.value || props.availability.resource?.image?.value) ?? ''
        }
        description={
          props.availability.description?.value ??
          props.availability.resource?.description?.value ??
          ''
        }
        handleToggle={handleToggle}
        toggle={props.formExpanded}
        title={props.availability.title?.value ?? props.availability.resource?.title ?? ''}
        disableActions
        booking={props.booking}
        availabilityId={props.availability.id}
        startTime={props.availability.startTime}
        endTime={props.availability.endTime}
        onUpdate={props.onUpdate}
        bookingColor={props.availability.color?.value ?? primary}
      />
      {props.formExpanded && (
        <>
          <BookingsToggleContainer>
            <ButtonSecondary
              onClick={() => {
                setShowBookings(!showBookings);
              }}
            >
              {showBookings ? 'Edit Event' : 'See Event Attendees'}
            </ButtonSecondary>
          </BookingsToggleContainer>
          {showBookings ? (
            <BookingsList booking={bookings.value?.items} />
          ) : (
            <EditEventForm onUpdate={props.onUpdate} {...props.availability} />
          )}
        </>
      )}
    </Layout>
  );
}

const Layout = styled.div`
  width: 100%;
  border-radius: 5px;
  box-shadow: 5px 5px 10px 0px #0000001a;
  display: block;
  flex-direction: column;
  transition: 0.2s;
  margin-bottom: 10px;
  background: white;
`;

const BookingsToggleContainer = styled.div`
  text-align: center;
`;
