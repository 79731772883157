import React from 'react';
import styled from 'styled-components';
import { confirm, confirmBooking } from './Dialog';
import grpc from '../../grpc';
import * as googleProtobufTimestamp from 'google-protobuf/google/protobuf/timestamp_pb';
import { BookingDTO } from '../../../Lib/sdk/Bookings_pb';
import { Button } from '../../kit/Button';
import { secondary } from '../../kit/Colors';
import { OwnerDetails } from './BookAvailabilityCard';
import { GlobeIcon } from '../../kit/Icon';

export interface CardContentProps {
  handleToggle: (value: boolean) => void;
  onUpdate?: () => void;
  toggle: boolean;
  title: string;
  description: string;
  imageUrl: string;
  disableActions: boolean;
  booking?: BookingDTO.AsObject;
  availabilityId: string;
  owner?: OwnerDetails;
  startTime?: googleProtobufTimestamp.Timestamp.AsObject;
  endTime?: googleProtobufTimestamp.Timestamp.AsObject;
  bookingColor: string;
  url?: string;
}

export function AvailabilityCardContent(props: CardContentProps) {
  const hasBooking = props.booking?.id !== undefined;
  const action_name = hasBooking ? 'Cancel' : 'Book';

  const onClick = async () => {
    if (props.booking?.id && (await confirm('Are you sure you want to cancel?'))) {
      await grpc.bookings.deleteMyBooking({ id: props.booking?.id }).then(() => {
        props.onUpdate && props.onUpdate();
      });
    } else if (!props.booking?.id) {
      const res = await confirmBooking(
        'Are you sure you want to book?',
        props.owner?.ownerEmail?.value,
        props.owner?.ownerFirstName?.value,
        props.owner?.ownerLastName?.value,
      );
      if (res.result) {
        await grpc.bookings.createBooking({
          availabilityId: props.availabilityId,
          ownerEmail: res.owner?.ownerEmail,
          ownerLastName: res.owner?.ownerLastName,
          ownerFirstName: res.owner?.ownerFirstName,
        });
      }
      props.onUpdate && props.onUpdate();
    }
  };

  return (
    <ContentTop>
      <ContentLeft>
        {props.imageUrl ? (
          <CoverImage src={props.imageUrl} />
        ) : (
          <CoverPlaceHolder src={require('../../assets/icons/CalendarAlt.svg')} />
        )}
      </ContentLeft>
      <ContentRight>
        <Header>{props.title}</Header>
        <Slug>{props.description}</Slug>
        {props.url && (
          <ContentText>
            <GlobeIcon size="sm" />
            <URLText target="blank" href={prependURL(props.url)}>
              {props.url}
            </URLText>
          </ContentText>
        )}
        <ButtonLayout>
          {!props.disableActions && (
            <Button
              pill
              secondary={hasBooking}
              color={!hasBooking ? props.bookingColor : undefined}
              onClick={onClick}
            >
              {action_name}
            </Button>
          )}
          <ToggleView onClick={() => props.handleToggle(!props.toggle)}>
            See {props.toggle ? 'Less' : 'More'}
          </ToggleView>
        </ButtonLayout>
      </ContentRight>
    </ContentTop>
  );
}

function prependURL(url: string) {
  if (url.startsWith('http://') || url.startsWith('https://')) {
    return url;
  } else {
    return `https://${url}`;
  }
}

const CoverImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CoverPlaceHolder = styled.img`
  width: 50%;
  height: 50%;
  margin: auto;
  display: block;
`;

const Header = styled.h2`
  margin: 0px;
  line-height: 1.1;
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

const ContentRight = styled.div`
  padding: 1rem;
  @media screen and (max-width: 450px) {
    padding: 0.5rem;
  }
  width: 70%;
  display: flex;
  flex-direction: column;
`;

const ContentLeft = styled.div`
  width: 30%;
  background-color: #f7f7f7;
  display: flex;
`;

const ContentTop = styled.div`
  display: flex;
  min-height: 150px;
  @media screen and (max-width: 450px) {
    min-height: 110px;
  }
  background-color: white;
`;

const ButtonLayout = styled.div`
  display: flex;
  margin-top: auto;
  justify-content: space-between;
  align-items: center;
`;

const ToggleView = styled.div`
  color: ${secondary};
  text-decoration: underline;
`;

const Slug = styled.div`
  height: auto;
  font-size: 0.9rem;
  line-height: 1.3;
  font-size: 12px;
  margin-bottom: 10px;
  color: ${secondary};
  @media screen and (max-width: 450px) {
    font-size: 0.7rem;
  }
`;

const ContentText = styled.div`
  display: flex;
  margin-top: 2px;
  align-items: center;
  margin-bottom: 10px;
`;

const URLText = styled.a`
  font-size: 14px;
  color: blue;
  margin-left: 10px;
  @media screen and (max-width: 450px) {
    font-size: 0.7rem;
  }
`;
