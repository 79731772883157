import * as React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { StartPage } from '../pages/StartPage';

export default function Routes() {
  return (
    <BrowserRouter>
      <Route
        path="/widgets/:widgetType"
        render={(routeProps) => <StartPage widgetType={routeProps.match.params} {...routeProps} />}
      />
    </BrowserRouter>
  );
}
