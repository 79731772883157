// Generated by protoc-gen-grpc-ts-web. DO NOT EDIT!
/* eslint-disable */
/* tslint:disable */

import * as jspb from 'google-protobuf';
import * as grpcWeb from 'grpc-web';

import * as core from './Core_pb';
import * as googleProtobufWrappers from 'google-protobuf/google/protobuf/wrappers_pb';

export class ResourcesService {

	private client_ = new grpcWeb.GrpcWebClientBase({
		format: 'text',
	});

	private methodInfoListResources = new grpcWeb.MethodDescriptor<ListResourcesReq, ListResourcesRes>(
		"ListResources",
		null,
		ListResourcesReq,
		ListResourcesRes,
		(req: ListResourcesReq) => req.serializeBinary(),
		ListResourcesRes.deserializeBinary
	);

	private methodInfoCreateResource = new grpcWeb.MethodDescriptor<CreateResourceReq, CreateResourceRes>(
		"CreateResource",
		null,
		CreateResourceReq,
		CreateResourceRes,
		(req: CreateResourceReq) => req.serializeBinary(),
		CreateResourceRes.deserializeBinary
	);

	private methodInfoUpdateResource = new grpcWeb.MethodDescriptor<UpdateResourceReq, UpdateResourceRes>(
		"UpdateResource",
		null,
		UpdateResourceReq,
		UpdateResourceRes,
		(req: UpdateResourceReq) => req.serializeBinary(),
		UpdateResourceRes.deserializeBinary
	);

	private methodInfoDeleteResource = new grpcWeb.MethodDescriptor<DeleteResourceReq, DeleteResourceRes>(
		"DeleteResource",
		null,
		DeleteResourceReq,
		DeleteResourceRes,
		(req: DeleteResourceReq) => req.serializeBinary(),
		DeleteResourceRes.deserializeBinary
	);

	private methodInfoGetResource = new grpcWeb.MethodDescriptor<GetResourceReq, GetResourceRes>(
		"GetResource",
		null,
		GetResourceReq,
		GetResourceRes,
		(req: GetResourceReq) => req.serializeBinary(),
		GetResourceRes.deserializeBinary
	);

	constructor(
		private hostname: string,
		private defaultMetadata?: () => grpcWeb.Metadata,
	) { }

	listResources(req: ListResourcesReq.AsObject, metadata?: grpcWeb.Metadata): Promise<ListResourcesRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = ListResourcesReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.ResourcesService/ListResources',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoListResources,
				(err: grpcWeb.Error, res: ListResourcesRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	createResource(req: CreateResourceReq.AsObject, metadata?: grpcWeb.Metadata): Promise<CreateResourceRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = CreateResourceReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.ResourcesService/CreateResource',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoCreateResource,
				(err: grpcWeb.Error, res: CreateResourceRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	updateResource(req: UpdateResourceReq.AsObject, metadata?: grpcWeb.Metadata): Promise<UpdateResourceRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = UpdateResourceReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.ResourcesService/UpdateResource',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoUpdateResource,
				(err: grpcWeb.Error, res: UpdateResourceRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	deleteResource(req: DeleteResourceReq.AsObject, metadata?: grpcWeb.Metadata): Promise<DeleteResourceRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = DeleteResourceReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.ResourcesService/DeleteResource',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoDeleteResource,
				(err: grpcWeb.Error, res: DeleteResourceRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	getResource(req: GetResourceReq.AsObject, metadata?: grpcWeb.Metadata): Promise<GetResourceRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = GetResourceReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.ResourcesService/GetResource',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoGetResource,
				(err: grpcWeb.Error, res: GetResourceRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

}




export declare namespace ListResourcesReq {
	export type AsObject = {
		projectId: string,
	}
}

export class ListResourcesReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, ListResourcesReq.repeatedFields_, null);
	}


	getProjectId(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setProjectId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		ListResourcesReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): ListResourcesReq.AsObject {
		let f: any;
		return {
			projectId: this.getProjectId(),
		};
	}

	static serializeBinaryToWriter(message: ListResourcesReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getProjectId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): ListResourcesReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new ListResourcesReq();
		return ListResourcesReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: ListResourcesReq, reader: jspb.BinaryReader): ListResourcesReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setProjectId(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace ListResourcesRes {
	export type AsObject = {
		page?: core.PageDTO.AsObject,
		items: Array<ResourceDTO.AsObject>,
	}
}

export class ListResourcesRes extends jspb.Message {

	private static repeatedFields_ = [
		2,
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, ListResourcesRes.repeatedFields_, null);
	}


	getPage(): core.PageDTO {
		return jspb.Message.getWrapperField(this, core.PageDTO, 1);
	}

	setPage(value?: core.PageDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	getItems(): Array<ResourceDTO> {
		return jspb.Message.getRepeatedWrapperField(this, ResourceDTO, 2);
	}

	setItems(value: Array<ResourceDTO>): void {
		(jspb.Message as any).setRepeatedWrapperField(this, 2, value);
	}

	addItems(value?: ResourceDTO, index?: number): ResourceDTO {
		return jspb.Message.addToRepeatedWrapperField(this, 2, value, ResourceDTO, index);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		ListResourcesRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): ListResourcesRes.AsObject {
		let f: any;
		return {
			page: (f = this.getPage()) && f.toObject(),
			items: this.getItems().map((item) => item.toObject()),
		};
	}

	static serializeBinaryToWriter(message: ListResourcesRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getPage();
		if (field1 != null) {
			writer.writeMessage(1, field1, core.PageDTO.serializeBinaryToWriter);
		}
		const field2 = message.getItems();
		if (field2.length > 0) {
			writer.writeRepeatedMessage(2, field2, ResourceDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): ListResourcesRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new ListResourcesRes();
		return ListResourcesRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: ListResourcesRes, reader: jspb.BinaryReader): ListResourcesRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new core.PageDTO();
				reader.readMessage(field1, core.PageDTO.deserializeBinaryFromReader);
				message.setPage(field1);
				break;
			case 2:
				const field2 = new ResourceDTO();
				reader.readMessage(field2, ResourceDTO.deserializeBinaryFromReader);
				message.addItems(field2);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateResourceReq {
	export type AsObject = {
		title: string,
		projectId: string,
		authTags?: core.AuthorizationTagsDTO.AsObject,
		description?: googleProtobufWrappers.StringValue.AsObject,
		image?: googleProtobufWrappers.StringValue.AsObject,
	}
}

export class CreateResourceReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateResourceReq.repeatedFields_, null);
	}


	getTitle(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setTitle(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	getProjectId(): string {return jspb.Message.getFieldWithDefault(this, 2, "");
	}

	setProjectId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 2, value);
	}

	getAuthTags(): core.AuthorizationTagsDTO {
		return jspb.Message.getWrapperField(this, core.AuthorizationTagsDTO, 3);
	}

	setAuthTags(value?: core.AuthorizationTagsDTO): void {
		(jspb.Message as any).setWrapperField(this, 3, value);
	}

	getDescription(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 4);
	}

	setDescription(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 4, value);
	}

	getImage(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 5);
	}

	setImage(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 5, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateResourceReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateResourceReq.AsObject {
		let f: any;
		return {
			title: this.getTitle(),
			projectId: this.getProjectId(),
			authTags: (f = this.getAuthTags()) && f.toObject(),
			description: (f = this.getDescription()) && f.toObject(),
			image: (f = this.getImage()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: CreateResourceReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getTitle();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
		const field2 = message.getProjectId();
		if (field2.length > 0) {
			writer.writeString(2, field2);
		}
		const field3 = message.getAuthTags();
		if (field3 != null) {
			writer.writeMessage(3, field3, core.AuthorizationTagsDTO.serializeBinaryToWriter);
		}
		const field4 = message.getDescription();
		if (field4 != null) {
			writer.writeMessage(4, field4, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field5 = message.getImage();
		if (field5 != null) {
			writer.writeMessage(5, field5, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateResourceReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateResourceReq();
		return CreateResourceReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateResourceReq, reader: jspb.BinaryReader): CreateResourceReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setTitle(field1);
				break;
			case 2:
				const field2 = reader.readString()
				message.setProjectId(field2);
				break;
			case 3:
				const field3 = new core.AuthorizationTagsDTO();
				reader.readMessage(field3, core.AuthorizationTagsDTO.deserializeBinaryFromReader);
				message.setAuthTags(field3);
				break;
			case 4:
				const field4 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field4, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setDescription(field4);
				break;
			case 5:
				const field5 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field5, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setImage(field5);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateResourceRes {
	export type AsObject = {
		resource?: ResourceDTO.AsObject,
	}
}

export class CreateResourceRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateResourceRes.repeatedFields_, null);
	}


	getResource(): ResourceDTO {
		return jspb.Message.getWrapperField(this, ResourceDTO, 1);
	}

	setResource(value?: ResourceDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateResourceRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateResourceRes.AsObject {
		let f: any;
		return {
			resource: (f = this.getResource()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: CreateResourceRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getResource();
		if (field1 != null) {
			writer.writeMessage(1, field1, ResourceDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateResourceRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateResourceRes();
		return CreateResourceRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateResourceRes, reader: jspb.BinaryReader): CreateResourceRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new ResourceDTO();
				reader.readMessage(field1, ResourceDTO.deserializeBinaryFromReader);
				message.setResource(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace UpdateResourceReq {
	export type AsObject = {
		id: string,
		title?: googleProtobufWrappers.StringValue.AsObject,
		description?: googleProtobufWrappers.StringValue.AsObject,
		image?: googleProtobufWrappers.StringValue.AsObject,
	}
}

export class UpdateResourceReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, UpdateResourceReq.repeatedFields_, null);
	}


	getId(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	getTitle(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 2);
	}

	setTitle(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 2, value);
	}

	getDescription(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 3);
	}

	setDescription(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 3, value);
	}

	getImage(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 4);
	}

	setImage(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 4, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		UpdateResourceReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): UpdateResourceReq.AsObject {
		let f: any;
		return {
			id: this.getId(),
			title: (f = this.getTitle()) && f.toObject(),
			description: (f = this.getDescription()) && f.toObject(),
			image: (f = this.getImage()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: UpdateResourceReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
		const field2 = message.getTitle();
		if (field2 != null) {
			writer.writeMessage(2, field2, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field3 = message.getDescription();
		if (field3 != null) {
			writer.writeMessage(3, field3, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field4 = message.getImage();
		if (field4 != null) {
			writer.writeMessage(4, field4, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): UpdateResourceReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new UpdateResourceReq();
		return UpdateResourceReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: UpdateResourceReq, reader: jspb.BinaryReader): UpdateResourceReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setId(field1);
				break;
			case 2:
				const field2 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field2, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setTitle(field2);
				break;
			case 3:
				const field3 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field3, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setDescription(field3);
				break;
			case 4:
				const field4 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field4, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setImage(field4);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace UpdateResourceRes {
	export type AsObject = {
		resource?: ResourceDTO.AsObject,
	}
}

export class UpdateResourceRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, UpdateResourceRes.repeatedFields_, null);
	}


	getResource(): ResourceDTO {
		return jspb.Message.getWrapperField(this, ResourceDTO, 1);
	}

	setResource(value?: ResourceDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		UpdateResourceRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): UpdateResourceRes.AsObject {
		let f: any;
		return {
			resource: (f = this.getResource()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: UpdateResourceRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getResource();
		if (field1 != null) {
			writer.writeMessage(1, field1, ResourceDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): UpdateResourceRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new UpdateResourceRes();
		return UpdateResourceRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: UpdateResourceRes, reader: jspb.BinaryReader): UpdateResourceRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new ResourceDTO();
				reader.readMessage(field1, ResourceDTO.deserializeBinaryFromReader);
				message.setResource(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace DeleteResourceReq {
	export type AsObject = {
		id: string,
	}
}

export class DeleteResourceReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, DeleteResourceReq.repeatedFields_, null);
	}


	getId(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		DeleteResourceReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): DeleteResourceReq.AsObject {
		let f: any;
		return {
			id: this.getId(),
		};
	}

	static serializeBinaryToWriter(message: DeleteResourceReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): DeleteResourceReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new DeleteResourceReq();
		return DeleteResourceReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: DeleteResourceReq, reader: jspb.BinaryReader): DeleteResourceReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setId(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace DeleteResourceRes {
	export type AsObject = {
	}
}

export class DeleteResourceRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, DeleteResourceRes.repeatedFields_, null);
	}


	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		DeleteResourceRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): DeleteResourceRes.AsObject {
		let f: any;
		return {
		};
	}

	static serializeBinaryToWriter(message: DeleteResourceRes, writer: jspb.BinaryWriter): void {
	}

	static deserializeBinary(bytes: Uint8Array): DeleteResourceRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new DeleteResourceRes();
		return DeleteResourceRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: DeleteResourceRes, reader: jspb.BinaryReader): DeleteResourceRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace GetResourceReq {
	export type AsObject = {
		id: string,
	}
}

export class GetResourceReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, GetResourceReq.repeatedFields_, null);
	}


	getId(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		GetResourceReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): GetResourceReq.AsObject {
		let f: any;
		return {
			id: this.getId(),
		};
	}

	static serializeBinaryToWriter(message: GetResourceReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): GetResourceReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new GetResourceReq();
		return GetResourceReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: GetResourceReq, reader: jspb.BinaryReader): GetResourceReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setId(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace GetResourceRes {
	export type AsObject = {
		resouce?: ResourceDTO.AsObject,
	}
}

export class GetResourceRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, GetResourceRes.repeatedFields_, null);
	}


	getResouce(): ResourceDTO {
		return jspb.Message.getWrapperField(this, ResourceDTO, 1);
	}

	setResouce(value?: ResourceDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		GetResourceRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): GetResourceRes.AsObject {
		let f: any;
		return {
			resouce: (f = this.getResouce()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: GetResourceRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getResouce();
		if (field1 != null) {
			writer.writeMessage(1, field1, ResourceDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): GetResourceRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new GetResourceRes();
		return GetResourceRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: GetResourceRes, reader: jspb.BinaryReader): GetResourceRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new ResourceDTO();
				reader.readMessage(field1, ResourceDTO.deserializeBinaryFromReader);
				message.setResouce(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace ResourceDTO {
	export type AsObject = {
		id: string,
		title: string,
		projectId: string,
		description?: googleProtobufWrappers.StringValue.AsObject,
		image?: googleProtobufWrappers.StringValue.AsObject,
	}
}

export class ResourceDTO extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, ResourceDTO.repeatedFields_, null);
	}


	getId(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	getTitle(): string {return jspb.Message.getFieldWithDefault(this, 2, "");
	}

	setTitle(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 2, value);
	}

	getProjectId(): string {return jspb.Message.getFieldWithDefault(this, 3, "");
	}

	setProjectId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 3, value);
	}

	getDescription(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 5);
	}

	setDescription(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 5, value);
	}

	getImage(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 6);
	}

	setImage(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 6, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		ResourceDTO.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): ResourceDTO.AsObject {
		let f: any;
		return {
			id: this.getId(),
			title: this.getTitle(),
			projectId: this.getProjectId(),
			description: (f = this.getDescription()) && f.toObject(),
			image: (f = this.getImage()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: ResourceDTO, writer: jspb.BinaryWriter): void {
		const field1 = message.getId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
		const field2 = message.getTitle();
		if (field2.length > 0) {
			writer.writeString(2, field2);
		}
		const field3 = message.getProjectId();
		if (field3.length > 0) {
			writer.writeString(3, field3);
		}
		const field5 = message.getDescription();
		if (field5 != null) {
			writer.writeMessage(5, field5, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field6 = message.getImage();
		if (field6 != null) {
			writer.writeMessage(6, field6, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): ResourceDTO {
		var reader = new jspb.BinaryReader(bytes);
		var message = new ResourceDTO();
		return ResourceDTO.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: ResourceDTO, reader: jspb.BinaryReader): ResourceDTO {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setId(field1);
				break;
			case 2:
				const field2 = reader.readString()
				message.setTitle(field2);
				break;
			case 3:
				const field3 = reader.readString()
				message.setProjectId(field3);
				break;
			case 5:
				const field5 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field5, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setDescription(field5);
				break;
			case 6:
				const field6 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field6, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setImage(field6);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}


function ListResourcesReqFromObject(obj: ListResourcesReq.AsObject | undefined): ListResourcesReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new ListResourcesReq();
	message.setProjectId(obj.projectId);
	return message;
}

function ListResourcesResFromObject(obj: ListResourcesRes.AsObject | undefined): ListResourcesRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new ListResourcesRes();
	message.setPage(PageDTOFromObject(obj.page));
	(obj.items || [])
		.map((item) => ResourceDTOFromObject(item))
		.forEach((item) => message.addItems(item));
	return message;
}

function PageDTOFromObject(obj: core.PageDTO.AsObject | undefined): core.PageDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new core.PageDTO();
	message.setPage(obj.page);
	message.setPageSize(obj.pageSize);
	message.setTotalCount(obj.totalCount);
	return message;
}

function ResourceDTOFromObject(obj: ResourceDTO.AsObject | undefined): ResourceDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new ResourceDTO();
	message.setId(obj.id);
	message.setTitle(obj.title);
	message.setProjectId(obj.projectId);
	message.setDescription(StringValueFromObject(obj.description));
	message.setImage(StringValueFromObject(obj.image));
	return message;
}

function StringValueFromObject(obj: googleProtobufWrappers.StringValue.AsObject | undefined): googleProtobufWrappers.StringValue | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new googleProtobufWrappers.StringValue();
	message.setValue(obj.value);
	return message;
}

function CreateResourceReqFromObject(obj: CreateResourceReq.AsObject | undefined): CreateResourceReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateResourceReq();
	message.setTitle(obj.title);
	message.setProjectId(obj.projectId);
	message.setAuthTags(AuthorizationTagsDTOFromObject(obj.authTags));
	message.setDescription(StringValueFromObject(obj.description));
	message.setImage(StringValueFromObject(obj.image));
	return message;
}

function AuthorizationTagsDTOFromObject(obj: core.AuthorizationTagsDTO.AsObject | undefined): core.AuthorizationTagsDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new core.AuthorizationTagsDTO();
	(obj.tagsMap || [])
		.forEach((entry) => message.getTagsMap().set(entry[0], entry[1]));
	return message;
}

function CreateResourceResFromObject(obj: CreateResourceRes.AsObject | undefined): CreateResourceRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateResourceRes();
	message.setResource(ResourceDTOFromObject(obj.resource));
	return message;
}

function UpdateResourceReqFromObject(obj: UpdateResourceReq.AsObject | undefined): UpdateResourceReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new UpdateResourceReq();
	message.setId(obj.id);
	message.setTitle(StringValueFromObject(obj.title));
	message.setDescription(StringValueFromObject(obj.description));
	message.setImage(StringValueFromObject(obj.image));
	return message;
}

function UpdateResourceResFromObject(obj: UpdateResourceRes.AsObject | undefined): UpdateResourceRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new UpdateResourceRes();
	message.setResource(ResourceDTOFromObject(obj.resource));
	return message;
}

function DeleteResourceReqFromObject(obj: DeleteResourceReq.AsObject | undefined): DeleteResourceReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new DeleteResourceReq();
	message.setId(obj.id);
	return message;
}

function DeleteResourceResFromObject(obj: DeleteResourceRes.AsObject | undefined): DeleteResourceRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new DeleteResourceRes();
	return message;
}

function GetResourceReqFromObject(obj: GetResourceReq.AsObject | undefined): GetResourceReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new GetResourceReq();
	message.setId(obj.id);
	return message;
}

function GetResourceResFromObject(obj: GetResourceRes.AsObject | undefined): GetResourceRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new GetResourceRes();
	message.setResouce(ResourceDTOFromObject(obj.resouce));
	return message;
}

