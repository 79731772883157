import React from 'react';
import styled from 'styled-components';
import { Button } from '../../kit/Button';
import { CheckCircleIcon } from '../../kit/Icon';

interface Props {
  handleNewBooking: () => void;
}

export function SuccessPage(props: Props) {
  return (
    <Layout>
      <CheckCircleIcon />
      <Text>Event successfully created!</Text>
      <Button secondary onClick={props.handleNewBooking}>
        New Event
      </Button>
    </Layout>
  );
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
`;

const Text = styled.p`
  font-size: 1rem;
`;
