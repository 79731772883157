import React, { useState } from 'react';
import styled from 'styled-components';
import { AvailabilityCardTopBar } from './AvailabilityCardTopBar';
import { AvailabilityCardContent } from './AvailabilityCardContent';
import { AvailabilityCardDetails } from './AvailabilityCardDetails';
import { AvailabilityDTO } from '../../../Lib/sdk/Availabilities_pb';
import wrappers from 'google-protobuf/google/protobuf/wrappers_pb';
import { BookingDTO } from '../../../Lib/sdk/Bookings_pb';
import { primary } from '../../kit/Colors';

export interface CardProps {
  onUpdate?: () => void;
  disableActions: boolean;
  availability: AvailabilityDTO.AsObject;
  booking?: BookingDTO.AsObject;
  owner: OwnerDetails;
}

export interface OwnerDetails {
  ownerEmail?: wrappers.StringValue.AsObject;
  ownerFirstName?: wrappers.StringValue.AsObject;
  ownerLastName?: wrappers.StringValue.AsObject;
}

export function AvailabilityCard(props: CardProps) {
  const [toggle, setToggle] = useState(false);

  const handleToggle = (value: boolean) => {
    setToggle(value);
  };

  return (
    <Layout>
      <AvailabilityCardTopBar
        startTime={props.availability.startTime!}
        endTime={props.availability.endTime!}
        resourceTitle={props.availability.resource?.title ?? ''}
        bookingColor={props.availability.color?.value ?? primary}
      />
      <AvailabilityCardContent
        imageUrl={
          (props.availability.image?.value || props.availability.resource?.image?.value) ?? ''
        }
        description={
          props.availability.description?.value ??
          props.availability.resource?.description?.value ??
          ''
        }
        handleToggle={handleToggle}
        toggle={toggle}
        title={props.availability.title?.value ?? props.availability.resource?.title ?? ''}
        disableActions={props.disableActions}
        booking={props.booking}
        availabilityId={props.availability.id}
        startTime={props.availability.startTime}
        endTime={props.availability.endTime}
        onUpdate={props.onUpdate}
        bookingColor={props.availability.color?.value ?? primary}
        owner={props.owner}
        url={props.availability.url?.value}
      />
      {toggle && (
        <AvailabilityCardDetails
          location={props.availability.location?.value ?? ''}
          cost={props.availability.cost?.value ?? ''}
          description={
            props.availability.description?.value ??
            props.availability.resource?.description?.value ??
            ''
          }
        />
      )}
    </Layout>
  );
}

const Layout = styled.div`
  width: 100%;
  border-radius: 5px;
  box-shadow: 5px 5px 10px 0px #0000001a;
  display: block;
  flex-direction: column;
  transition: 0.2s;
  margin-bottom: 10px;
`;
