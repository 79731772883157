import { keyBy } from 'lodash-es';
import React from 'react';
import useOnceAsync from '../../Lib/hooks/UseOnceAsync';
import { AvailabilityFilterDTO, AvailabilityDTO } from '../../Lib/sdk/Availabilities_pb';
import { EventsListWidgetDTO } from '../../Lib/sdk/Widgets_pb';
import { BookingDTO } from '../../Lib/sdk/Bookings_pb';
import { AvailabilityCard } from '../components/AvailabilityCard/BookAvailabilityCard';
import { Role, sharedUserService } from '../../Lib/authentication/UserService';
import grpc from '../grpc';
import { NoEventsCard } from '../components/AvailabilityCard/NoEventsCard';

interface Dictionary<T> {
  [index: string]: T;
}

export function EventsListWidget(props: EventsListWidgetDTO.AsObject) {
  const isAnonymousWidget = sharedUserService.roles().includes(Role.AnonymousWidget);

  const getBooking = async () => {
    if (isAnonymousWidget) {
      return {};
    }

    const response = await grpc.bookings.listMyBookings({
      projectId: props.projectId,
    });

    return keyBy(response.items, (e: BookingDTO.AsObject) => e.availabilityId);
  };

  const getAvailability = async (
    bookings: Dictionary<BookingDTO.AsObject>,
  ): Promise<AvailabilityDTO.AsObject[]> => {
    const response = await grpc.availabilities.listAvailabilities({
      startTime: props.since,
      endTime: props.until,
      projectId: props.projectId,
    });

    if (isAnonymousWidget) {
      return response.items;
    }

    return response.items.filter((availability) => {
      switch (props.availabilityFilter) {
        case AvailabilityFilterDTO.AVAILABILITY_FILTER_ALL:
        case AvailabilityFilterDTO.AVAILABILITY_FILTER_UNSPECIFIED:
          return true;
        case AvailabilityFilterDTO.AVAILABILITY_FILTER_BOOKED:
          // true when there is a booking for this availability
          return bookings![availability.id] !== undefined;
        case AvailabilityFilterDTO.AVAILABILITY_FILTER_NOT_BOOKED:
          // true when there is NOT a booking for this availability
          return bookings![availability.id] === undefined;
      }
    });
  };

  const response = useOnceAsync(async () => {
    const bookingsResponse = await getBooking();
    const availabilitiesResponse = await getAvailability(bookingsResponse);
    return { bookingsResponse, availabilitiesResponse };
  });

  const availabilities = response.value?.availabilitiesResponse;
  const bookings = response.value?.bookingsResponse;

  const onUpdate = () => {
    response.refresh();
  };

  if (!availabilities || !bookings) {
    // TODO: loading indicator
    return null;
  }

  if (availabilities.length === 0) {
    return <NoEventsCard />;
  }

  return (
    <div>
      {availabilities.map((availability) => (
        <AvailabilityCard
          key={availability.id}
          disableActions={isAnonymousWidget || availability.disableActions}
          onUpdate={onUpdate}
          availability={availability}
          booking={bookings ? bookings[availability.id] : undefined}
          owner={{
            ownerEmail: props.ownerEmail,
            ownerFirstName: props.ownerFirstName,
            ownerLastName: props.ownerLastName,
          }}
        />
      ))}
    </div>
  );
}
