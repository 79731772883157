import React from 'react';
import { Layout } from '../../kit/Form';
import { BookingDTO } from '../../../Lib/sdk/Bookings_pb';
import styled from 'styled-components';
import { format } from 'date-fns';
import { toDate } from '../../grpc-lib';

interface Props {
  booking?: BookingDTO.AsObject[];
}

export const BookingsList = (props: Props) => {
  return (
    <Layout>
      <TableWrapper>
        <Table>
          <TableHeader>
            <td>#</td>
            <td>First Name</td>
            <td>Last Name</td>
            <td>Email</td>
            <td>Booked On</td>
          </TableHeader>
          <tbody>
            {props.booking?.map((booking, index) => {
              return (
                <tr style={{ verticalAlign: 'middle' }} key={booking.id}>
                  <td>{index + 1}</td>
                  <td>{booking.ownerFirstName}</td>
                  <td>{booking.ownerLastName}</td>
                  <td>{booking.ownerEmail}</td>
                  <td>{format(toDate(booking.metadata?.createdAt), 'dd-MM-yyyy')}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </TableWrapper>
    </Layout>
  );
};

const Table = styled.table`
  width: 100%;
  margin-left: 20px;
  margin-right: 20px;
`;

const TableHeader = styled.tr`
  font-weight: bold;
`;

const TableWrapper = styled.div`
  width: 100%;
  margin: 10px;
  overflow: scroll;
`;
