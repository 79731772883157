// Generated by protoc-gen-grpc-ts-web. DO NOT EDIT!
/* eslint-disable */
/* tslint:disable */

import * as jspb from 'google-protobuf';
import * as grpcWeb from 'grpc-web';

import * as googleProtobufTimestamp from 'google-protobuf/google/protobuf/timestamp_pb';
import * as googleProtobufWrappers from 'google-protobuf/google/protobuf/wrappers_pb';






export declare namespace MetadataDTO {
	export type AsObject = {
		createdAt?: googleProtobufTimestamp.Timestamp.AsObject,
		updatedAt?: googleProtobufTimestamp.Timestamp.AsObject,
	}
}

export class MetadataDTO extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, MetadataDTO.repeatedFields_, null);
	}


	getCreatedAt(): googleProtobufTimestamp.Timestamp {
		return jspb.Message.getWrapperField(this, googleProtobufTimestamp.Timestamp, 1);
	}

	setCreatedAt(value?: googleProtobufTimestamp.Timestamp): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	getUpdatedAt(): googleProtobufTimestamp.Timestamp {
		return jspb.Message.getWrapperField(this, googleProtobufTimestamp.Timestamp, 2);
	}

	setUpdatedAt(value?: googleProtobufTimestamp.Timestamp): void {
		(jspb.Message as any).setWrapperField(this, 2, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		MetadataDTO.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): MetadataDTO.AsObject {
		let f: any;
		return {
			createdAt: (f = this.getCreatedAt()) && f.toObject(),
			updatedAt: (f = this.getUpdatedAt()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: MetadataDTO, writer: jspb.BinaryWriter): void {
		const field1 = message.getCreatedAt();
		if (field1 != null) {
			writer.writeMessage(1, field1, googleProtobufTimestamp.Timestamp.serializeBinaryToWriter);
		}
		const field2 = message.getUpdatedAt();
		if (field2 != null) {
			writer.writeMessage(2, field2, googleProtobufTimestamp.Timestamp.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): MetadataDTO {
		var reader = new jspb.BinaryReader(bytes);
		var message = new MetadataDTO();
		return MetadataDTO.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: MetadataDTO, reader: jspb.BinaryReader): MetadataDTO {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new googleProtobufTimestamp.Timestamp();
				reader.readMessage(field1, googleProtobufTimestamp.Timestamp.deserializeBinaryFromReader);
				message.setCreatedAt(field1);
				break;
			case 2:
				const field2 = new googleProtobufTimestamp.Timestamp();
				reader.readMessage(field2, googleProtobufTimestamp.Timestamp.deserializeBinaryFromReader);
				message.setUpdatedAt(field2);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace PageDTO {
	export type AsObject = {
		page: number,
		pageSize: number,
		totalCount: number,
	}
}

export class PageDTO extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, PageDTO.repeatedFields_, null);
	}


	getPage(): number {return jspb.Message.getFieldWithDefault(this, 1, 0);
	}

	setPage(value: number): void {
		(jspb.Message as any).setProto3IntField(this, 1, value);
	}

	getPageSize(): number {return jspb.Message.getFieldWithDefault(this, 2, 0);
	}

	setPageSize(value: number): void {
		(jspb.Message as any).setProto3IntField(this, 2, value);
	}

	getTotalCount(): number {return jspb.Message.getFieldWithDefault(this, 3, 0);
	}

	setTotalCount(value: number): void {
		(jspb.Message as any).setProto3IntField(this, 3, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		PageDTO.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): PageDTO.AsObject {
		let f: any;
		return {
			page: this.getPage(),
			pageSize: this.getPageSize(),
			totalCount: this.getTotalCount(),
		};
	}

	static serializeBinaryToWriter(message: PageDTO, writer: jspb.BinaryWriter): void {
		const field1 = message.getPage();
		if (field1 != 0) {
			writer.writeInt32(1, field1);
		}
		const field2 = message.getPageSize();
		if (field2 != 0) {
			writer.writeInt32(2, field2);
		}
		const field3 = message.getTotalCount();
		if (field3 != 0) {
			writer.writeInt32(3, field3);
		}
	}

	static deserializeBinary(bytes: Uint8Array): PageDTO {
		var reader = new jspb.BinaryReader(bytes);
		var message = new PageDTO();
		return PageDTO.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: PageDTO, reader: jspb.BinaryReader): PageDTO {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readInt32()
				message.setPage(field1);
				break;
			case 2:
				const field2 = reader.readInt32()
				message.setPageSize(field2);
				break;
			case 3:
				const field3 = reader.readInt32()
				message.setTotalCount(field3);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace PageQueryDTO {
	export type AsObject = {
		page?: googleProtobufWrappers.Int32Value.AsObject,
		pageSize?: googleProtobufWrappers.Int32Value.AsObject,
	}
}

export class PageQueryDTO extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, PageQueryDTO.repeatedFields_, null);
	}


	getPage(): googleProtobufWrappers.Int32Value {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.Int32Value, 1);
	}

	setPage(value?: googleProtobufWrappers.Int32Value): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	getPageSize(): googleProtobufWrappers.Int32Value {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.Int32Value, 2);
	}

	setPageSize(value?: googleProtobufWrappers.Int32Value): void {
		(jspb.Message as any).setWrapperField(this, 2, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		PageQueryDTO.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): PageQueryDTO.AsObject {
		let f: any;
		return {
			page: (f = this.getPage()) && f.toObject(),
			pageSize: (f = this.getPageSize()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: PageQueryDTO, writer: jspb.BinaryWriter): void {
		const field1 = message.getPage();
		if (field1 != null) {
			writer.writeMessage(1, field1, googleProtobufWrappers.Int32Value.serializeBinaryToWriter);
		}
		const field2 = message.getPageSize();
		if (field2 != null) {
			writer.writeMessage(2, field2, googleProtobufWrappers.Int32Value.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): PageQueryDTO {
		var reader = new jspb.BinaryReader(bytes);
		var message = new PageQueryDTO();
		return PageQueryDTO.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: PageQueryDTO, reader: jspb.BinaryReader): PageQueryDTO {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new googleProtobufWrappers.Int32Value();
				reader.readMessage(field1, googleProtobufWrappers.Int32Value.deserializeBinaryFromReader);
				message.setPage(field1);
				break;
			case 2:
				const field2 = new googleProtobufWrappers.Int32Value();
				reader.readMessage(field2, googleProtobufWrappers.Int32Value.deserializeBinaryFromReader);
				message.setPageSize(field2);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace AuthorizationTagsDTO {
	export type AsObject = {
		tagsMap: Array<[string, string]>,
	}
}

export class AuthorizationTagsDTO extends jspb.Message {

	private static repeatedFields_ = [
		1,
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, AuthorizationTagsDTO.repeatedFields_, null);
	}


	getTagsMap(): jspb.Map<string, string> {
		// @ts-ignore Argument of type 'null' is not assignable to parameter of type 'typeof Message'.ts
		// The property does exist but @types/google-protobuf is out of date.
		return jspb.Message.getMapField(this, 1, false, null);
	}

	clearTagsMap(): AuthorizationTagsDTO {
		this.getTagsMap().clear();
		return this;
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		AuthorizationTagsDTO.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): AuthorizationTagsDTO.AsObject {
		let f: any;
		return {
			tagsMap: (f = this.getTagsMap()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: AuthorizationTagsDTO, writer: jspb.BinaryWriter): void {
		const field1 = message.getTagsMap();
		if (field1.getLength() > 0) {
			// @ts-ignore Property 'serializeBinary' does not exist on type 'Map<K, V>'
			// The property does exist but @types/google-protobuf is out of date.
			field1.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeString)
		}
	}

	static deserializeBinary(bytes: Uint8Array): AuthorizationTagsDTO {
		var reader = new jspb.BinaryReader(bytes);
		var message = new AuthorizationTagsDTO();
		return AuthorizationTagsDTO.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: AuthorizationTagsDTO, reader: jspb.BinaryReader): AuthorizationTagsDTO {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = message.getTagsMap();
				/* reader.readMessage(value, function(message, reader) {
					jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readString, null, "", "");
					}); */
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}


function MetadataDTOFromObject(obj: MetadataDTO.AsObject | undefined): MetadataDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new MetadataDTO();
	message.setCreatedAt(TimestampFromObject(obj.createdAt));
	message.setUpdatedAt(TimestampFromObject(obj.updatedAt));
	return message;
}

function TimestampFromObject(obj: googleProtobufTimestamp.Timestamp.AsObject | undefined): googleProtobufTimestamp.Timestamp | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new googleProtobufTimestamp.Timestamp();
	message.setSeconds(obj.seconds);
	message.setNanos(obj.nanos);
	return message;
}

function PageDTOFromObject(obj: PageDTO.AsObject | undefined): PageDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new PageDTO();
	message.setPage(obj.page);
	message.setPageSize(obj.pageSize);
	message.setTotalCount(obj.totalCount);
	return message;
}

function PageQueryDTOFromObject(obj: PageQueryDTO.AsObject | undefined): PageQueryDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new PageQueryDTO();
	message.setPage(Int32ValueFromObject(obj.page));
	message.setPageSize(Int32ValueFromObject(obj.pageSize));
	return message;
}

function Int32ValueFromObject(obj: googleProtobufWrappers.Int32Value.AsObject | undefined): googleProtobufWrappers.Int32Value | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new googleProtobufWrappers.Int32Value();
	message.setValue(obj.value);
	return message;
}

function AuthorizationTagsDTOFromObject(obj: AuthorizationTagsDTO.AsObject | undefined): AuthorizationTagsDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new AuthorizationTagsDTO();
	(obj.tagsMap || [])
		.forEach((entry) => message.getTagsMap().set(entry[0], entry[1]));
	return message;
}

