import { metadata, registerDevTools } from '../Lib/grpc';
import { AvailabilitiesService } from '../Lib/sdk/Availabilities_pb';
import { BookingsService } from '../Lib/sdk/Bookings_pb';
import { ResourcesService } from '../Lib/sdk/Resources_pb';
import { environment } from './Environment';

const host = environment.apiUrl;

const grpc = {
  bookings: new BookingsService(host, metadata),
  availabilities: new AvailabilitiesService(host, metadata),
  resource: new ResourcesService(host, metadata),
};

registerDevTools(grpc);

export default grpc;
