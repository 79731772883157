import styled, { css } from 'styled-components';
import { primary, secondary } from './Colors';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  secondary?: boolean;
  pill?: boolean;
  color?: string;
}

export const Button = styled.button<Props>`
  padding: 0.75rem 1.25rem;
  border: none;
  border-radius: 8px;
  color: white;
  background: ${primary};

  ${(props) => {
    if (props.pill) {
      return css`
        border-radius: 9999px;
      `;
    }
  }}

  ${(props) => {
    if (props.secondary) {
      return css`
        background: ${secondary};
      `;
    }
  }}

    ${(props) => {
    if (props.color) {
      return css`
        background: ${props.color};
      `;
    }
  }}
`;
