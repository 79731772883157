import React, { useState } from 'react';
import grpc from '../grpc';
import { CreateEventsManagementWidgetDTO } from '../../Lib/sdk/Widgets_pb';
import useOnceAsync from '../../Lib/hooks/UseOnceAsync';
import { ManageCard } from '../components/AvailabilityCard/ManageAvailabilityCard';
import { NoEventsCard } from '../components/AvailabilityCard/NoEventsCard';

export function ManageEventsListWidget(props: CreateEventsManagementWidgetDTO.AsObject) {
  const [expandedAvailability, setExpandedAvailability] = useState('');

  const availabilities = useOnceAsync(async () => {
    return await grpc.availabilities.listAvailabilities({
      startTime: props.since,
      endTime: props.until,
      projectId: props.projectId,
    });
  });

  const onUpdate = () => {
    availabilities.refresh();
  };

  return (
    <div>
      {availabilities.value && availabilities.value?.items.length > 0 ? (
        availabilities.value.items.map((availability) => (
          <ManageCard
            key={availability.id}
            onUpdate={onUpdate}
            disableActions={false}
            availability={availability}
            formExpanded={availability.id === expandedAvailability}
            setFormExpanded={setExpandedAvailability}
            booking={undefined}
          />
        ))
      ) : (
        <NoEventsCard />
      )}
    </div>
  );
}
