import React from 'react';
import styled, { css } from 'styled-components';

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  size?: 'sm';
}

export function CheckCircleIcon(props: Props) {
  return <StyledIcon src={require('../assets/icons/CheckCircle.svg')} {...props} />;
}

export function CalendarTimesIcon(props: Props) {
  return <StyledIcon src={require('../assets/icons/CalendarTimes.svg')} {...props} />;
}

export function DollarIcon(props: Props) {
  return <StyledIcon src={require('../assets/icons/Dollar.svg')} {...props} />;
}

export function GlobeIcon(props: Props) {
  return <StyledIcon src={require('../assets/icons/Globe.svg')} {...props} />;
}

export function LocationIcon(props: Props) {
  return <StyledIcon src={require('../assets/icons/Location.svg')} {...props} />;
}

const StyledIcon = styled.img<Props>`
  display: block;
  width: 5rem;
  height: auto;
  ${(props) => {
    if (props.size === 'sm') {
      return css`
        width: 15px;
        height: 15px;
      `;
    }
  }}
`;
