import React from 'react';
import styled, { keyframes } from 'styled-components';
import { LocationIcon, DollarIcon } from '../../kit/Icon';
import { secondary } from '../../kit/Colors';

interface Props {
  location: string;
  cost: string;
  description: string;
}

export function AvailabilityCardDetails(props: Props) {
  return (
    <ContentBottom>
      {props.location && (
        <ContentText>
          <LocationIcon size="sm" />
          <Text>{props.location}</Text>
        </ContentText>
      )}
      {props.cost && (
        <ContentText>
          <DollarIcon size="sm" />
          <Text>{props.cost}</Text>
        </ContentText>
      )}
      <Description>{props.description}</Description>
    </ContentBottom>
  );
}

const Text = styled.p`
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 14px;
  color: ${secondary};
  @media screen and (max-width: 450px) {
    font-size: 0.7rem;
  }
  margin-left: 10px;
`;

const Description = styled.p`
  color: ${secondary};
  margin-top: 10px;
  margin-bottom: 0px;
  font-size: 1rem;
  @media screen and (max-width: 450px) {
    font-size: 0.7rem;
  }
`;

const ContentText = styled.div`
  display: flex;
  margin-top: 2px;
  align-items: center;
`;

const fade = keyframes`
    0% { opacity: 0;}
    100% { opacity: 1;}
`;

const ContentBottom = styled.div`
  padding: 15px;
  animation-name: ${fade};
  animation-duration: 0.5s;
  background: white;
`;
