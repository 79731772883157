import React from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { DialogContent } from '@material-ui/core';
import { OwnerDetails } from '../AvailabilityCard/BookAvailabilityCard';
import styled from 'styled-components';
interface ContentProps {
  close: () => void;
}

export function present(Content: React.FC<ContentProps>) {
  requestAnimationFrame(() => {
    const root = document.createElement('div');
    document.body.appendChild(root);
    const close = () => {
      unmountComponentAtNode(root);
      document.removeChild(root);
    };
    render(<Content close={close} />, root);
  });
}

export function confirm(message: string) {
  return new Promise<boolean>((resolve) => {
    present(({ close }) => {
      const done = (result: boolean) => {
        resolve(result);
        close();
      };
      return (
        <Dialog open={true}>
          <DialogTitle>{message}</DialogTitle>
          <DialogActions>
            <Button onClick={() => done(false)} color="secondary">
              No
            </Button>
            <Button onClick={() => done(true)} color="primary">
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      );
    });
  });
}

interface BookingConfirmation {
  result: boolean;
  owner?: OwnerDetails;
}

export function confirmBooking(message: string, email?: string, fname?: string, lname?: string) {
  return new Promise<BookingConfirmation>((resolve) => {
    present(({ close }) => {
      const done = (result: boolean) => {
        const ownerEmail = {
          value: (document.getElementById('email') as HTMLInputElement).value,
        };
        const ownerFirstName = {
          value: (document.getElementById('fname') as HTMLInputElement).value,
        };
        const ownerLastName = {
          value: (document.getElementById('lname') as HTMLInputElement).value,
        };
        const owner = { ownerEmail, ownerFirstName, ownerLastName };
        resolve({ result, owner });
        close();
      };
      return (
        <Dialog open={true}>
          <DialogTitle>{message}</DialogTitle>
          <form
            onSubmit={() => {
              done(true);
            }}
          >
            <DialogContent>
              <ConfirmBookingFormLayout>
                <label>First name:</label>
                <Input type="text" id="fname" name="fname" required defaultValue={fname} />
                <label>Last name:</label>
                <Input type="text" id="lname" name="lname" required defaultValue={lname} />
                <label>Email:</label>
                <Input type="text" id="email" name="email" required defaultValue={email} />
              </ConfirmBookingFormLayout>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => done(false)} color="secondary">
                No
              </Button>
              <Button type="submit" color="primary">
                Yes
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      );
    });
  });
}

const ConfirmBookingFormLayout = styled.div`
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-gap: 2px;
`;

const Input = styled.input`
  background-color: whitesmoke;
  border: none;
  font-family: Nunito;
  border-radius: 2px;
`;
