import { useState, useEffect } from 'react';

type AsyncCallback<T> = () => Promise<T>;

export interface AsyncResource<T> {
  value?: T;
  error?: Error;
  loading: boolean;
  refresh: () => Promise<void>;
}

export default function useOnceAsync<T>(
  fetcher: AsyncCallback<T>,
  deps?: React.DependencyList,
): AsyncResource<T> {
  const [value, setValue] = useState<T | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [loading, setLoading] = useState(true);

  async function getResource() {
    try {
      setLoading(true);
      const result = await fetcher();
      setValue(result);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getResource();
  }, deps ?? []);

  return {
    value,
    error,
    loading,
    refresh: getResource,
  };
}
