import styled from 'styled-components';

export const Layout = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const Error = styled.div`
  color: red;
`;

export const Form = styled.form`
  width: 100%;
  padding: 10px;
`;

export const ButtonArea = styled.div`
  text-align: center;
`;

export const Button = styled.button`
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  padding: 0.75rem 1.25rem;
  border: none;
  border-radius: 8px;
  justify-self: start;
  color: rgb(255, 255, 255);
  background: rgb(251, 133, 0);
  margin: 5px;
`;

export const ButtonDanger = styled(Button)`
  background: red;
`;

export const ButtonSecondary = styled(Button)`
  background: #cac5c5;
`;
