import React from 'react';
import styled from 'styled-components';
import { DeepMap, FieldError } from 'react-hook-form';
import { FormFieldError } from '../../kit/FormFieldError';
import { FrequencyDTO } from '../../../Lib/sdk/Availabilities_pb';

interface Props {
  errors: DeepMap<FormFields, FieldError>;
  repeats: boolean;
  toggleRepeat: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: any;
  disabled?: boolean;
}

export type FormFields = {
  orgName: string;
  title: string;
  description: string;
  maxParticipants: number;
  startTime: Date;
  endTime: Date;
  startDate: Date;
  location: string;
  cost: string;
  url: string;
  color: string;
  frequency: FrequencyDTO;
  endRepeatDate: Date;
  image: string;
  disableActions: boolean;
};

// eslint-disable-next-line max-lines-per-function
export const EventForm = (props: Props) => {
  return (
    <div>
      <FormGroup>
        <label>Organisation Name</label>
        <Input
          name="orgName"
          type="text"
          disabled={props.disabled}
          ref={props.register({ required: true })}
          placeholder="name..."
        />
        <FormFieldError fieldError={props.errors.orgName} name="Resource Title" />
      </FormGroup>
      <FormGroup>
        <label>Event Title</label>
        <Input
          name="title"
          type="text"
          disabled={props.disabled}
          ref={props.register({ required: true })}
          placeholder="title..."
        />
        <FormFieldError fieldError={props.errors.title} name="Title" />
      </FormGroup>
      <FormGroup>
        <label>Description</label>
        <TextArea
          name="description"
          disabled={props.disabled}
          ref={props.register()}
          placeholder="description..."
        />
      </FormGroup>
      <FormGroup>
        <label>Max Participants</label>
        <Input
          name="maxParticipants"
          type="number"
          disabled={props.disabled}
          ref={props.register({ required: true })}
          placeholder="participants..."
        />
        <FormFieldError fieldError={props.errors.maxParticipants} name="Max Participants" />
      </FormGroup>
      <TwoColumnGrid>
        <FormGroup>
          <label>Start Time</label>
          <Input
            name="startTime"
            type="time"
            disabled={props.disabled}
            ref={props.register({ required: true })}
          />
          <FormFieldError fieldError={props.errors.startTime} name="Start Time" />
        </FormGroup>
        <FormGroup>
          <label>End Time</label>
          <Input
            name="endTime"
            type="time"
            disabled={props.disabled}
            ref={props.register({ required: true })}
          />
          <FormFieldError fieldError={props.errors.endTime} name="End Time" />
        </FormGroup>
      </TwoColumnGrid>
      <TwoColumnGrid>
        <FormGroup>
          <label>Start Date</label>
          <Input
            name="startDate"
            type="date"
            ref={props.register({
              required: true,
              valueAsDate: true,
            })}
            disabled={props.disabled}
          />
          <FormFieldError fieldError={props.errors.startDate} name="Start Date" />
        </FormGroup>
        <FormGroup></FormGroup>
      </TwoColumnGrid>
      <FormGroup>
        <label>Location</label>
        <Input
          name="location"
          disabled={props.disabled}
          ref={props.register()}
          placeholder="location..."
        />
      </FormGroup>
      <FormGroup>
        <label>Cost</label>
        <Input name="cost" disabled={props.disabled} ref={props.register()} placeholder="cost..." />
      </FormGroup>
      <FormGroup>
        <label>URL</label>
        <Input name="url" disabled={props.disabled} ref={props.register()} placeholder="url..." />
      </FormGroup>
      <FormGroup>
        <label>Disable Actions</label>
        <Input
          name="disableActions"
          type="checkbox"
          disabled={props.disabled}
          ref={props.register()}
        />
      </FormGroup>
      <FormGroup>
        <label>Image URL</label>
        <Input
          name="image"
          disabled={props.disabled}
          ref={props.register()}
          placeholder="image..."
        />
      </FormGroup>
      <div>
        <label>Color</label>
        <ColorInput
          name="color"
          type="color"
          disabled={props.disabled}
          defaultValue="#FF7100"
          ref={props.register()}
        />
      </div>
      {props.repeats && (
        <div>
          <TwoColumnGrid>
            <FormGroup>
              <label>&nbsp;</label>
              <Select
                name="frequency"
                disabled={props.disabled}
                ref={props.register({ required: props.repeats })}
                defaultValue={FrequencyDTO.WEEKLY}
              >
                <option value={FrequencyDTO.DAILY}>Repeats Daily</option>
                <option value={FrequencyDTO.WEEKLY}>Repeats Weekly</option>
                <option value={FrequencyDTO.FORTNIGHTLY}>Repeats Fortnightly</option>
                <option value={FrequencyDTO.MONTHLY}>Repeats Monthly</option>
              </Select>
            </FormGroup>

            <FormGroup>
              <label>End Repeat Date</label>
              <Input
                name="endRepeatDate"
                type="date"
                ref={props.register({ required: props.repeats, valueAsDate: true })}
                disabled={props.disabled}
              />
              <FormFieldError fieldError={props.errors.endRepeatDate} name="Repeat End Date" />
            </FormGroup>
          </TwoColumnGrid>
        </div>
      )}
    </div>
  );
};

const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  @media screen and (max-width: 450px) {
    display: block;
  }
`;

const Input = styled.input`
  padding: 0.75rem;
  border: 1px solid rgb(209, 213, 219);
  border-radius: 8px;
  width: auto;
  font-family: Nunito;
`;

const ColorInput = styled.input`
  padding: 0.75rem;
  border-radius: 5px;
  width: 4rem;
  margin-left: 0.5rem;
  border: none;
`;

const TextArea = styled.textarea`
  padding: 0.75rem;
  border: 1px solid rgb(209, 213, 219);
  border-radius: 8px;
  width: auto;
  font-family: Nunito;
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Select = styled.select`
  position: relative;
  padding: 0.75rem;
  padding-right: 1.875rem;
  border: 1px solid rgb(209, 213, 219);
  border-radius: 8px;
  width: auto;
  color: black;
  line-height: 1.3rem;

  &::-ms-expand {
    display: none;
  }
`;
