// Generated by protoc-gen-grpc-ts-web. DO NOT EDIT!
/* eslint-disable */
/* tslint:disable */

import * as jspb from 'google-protobuf';
import * as grpcWeb from 'grpc-web';

import * as googleProtobufTimestamp from 'google-protobuf/google/protobuf/timestamp_pb';
import * as googleProtobufWrappers from 'google-protobuf/google/protobuf/wrappers_pb';
import * as core from './Core_pb';

export class BookingsService {

	private client_ = new grpcWeb.GrpcWebClientBase({
		format: 'text',
	});

	private methodInfoListBookings = new grpcWeb.MethodDescriptor<ListBookingsReq, ListBookingsRes>(
		"ListBookings",
		null,
		ListBookingsReq,
		ListBookingsRes,
		(req: ListBookingsReq) => req.serializeBinary(),
		ListBookingsRes.deserializeBinary
	);

	private methodInfoListMyBookings = new grpcWeb.MethodDescriptor<ListMyBookingsReq, ListMyBookingsRes>(
		"ListMyBookings",
		null,
		ListMyBookingsReq,
		ListMyBookingsRes,
		(req: ListMyBookingsReq) => req.serializeBinary(),
		ListMyBookingsRes.deserializeBinary
	);

	private methodInfoCreateBooking = new grpcWeb.MethodDescriptor<CreateBookingReq, CreateBookingRes>(
		"CreateBooking",
		null,
		CreateBookingReq,
		CreateBookingRes,
		(req: CreateBookingReq) => req.serializeBinary(),
		CreateBookingRes.deserializeBinary
	);

	private methodInfoUpdateBooking = new grpcWeb.MethodDescriptor<UpdateBookingReq, UpdateBookingRes>(
		"UpdateBooking",
		null,
		UpdateBookingReq,
		UpdateBookingRes,
		(req: UpdateBookingReq) => req.serializeBinary(),
		UpdateBookingRes.deserializeBinary
	);

	private methodInfoDeleteBooking = new grpcWeb.MethodDescriptor<DeleteBookingReq, DeleteBookingRes>(
		"DeleteBooking",
		null,
		DeleteBookingReq,
		DeleteBookingRes,
		(req: DeleteBookingReq) => req.serializeBinary(),
		DeleteBookingRes.deserializeBinary
	);

	private methodInfoDeleteMyBooking = new grpcWeb.MethodDescriptor<DeleteMyBookingReq, DeleteMyBookingRes>(
		"DeleteMyBooking",
		null,
		DeleteMyBookingReq,
		DeleteMyBookingRes,
		(req: DeleteMyBookingReq) => req.serializeBinary(),
		DeleteMyBookingRes.deserializeBinary
	);

	constructor(
		private hostname: string,
		private defaultMetadata?: () => grpcWeb.Metadata,
	) { }

	listBookings(req: ListBookingsReq.AsObject, metadata?: grpcWeb.Metadata): Promise<ListBookingsRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = ListBookingsReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.BookingsService/ListBookings',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoListBookings,
				(err: grpcWeb.Error, res: ListBookingsRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	listMyBookings(req: ListMyBookingsReq.AsObject, metadata?: grpcWeb.Metadata): Promise<ListMyBookingsRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = ListMyBookingsReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.BookingsService/ListMyBookings',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoListMyBookings,
				(err: grpcWeb.Error, res: ListMyBookingsRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	createBooking(req: CreateBookingReq.AsObject, metadata?: grpcWeb.Metadata): Promise<CreateBookingRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = CreateBookingReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.BookingsService/CreateBooking',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoCreateBooking,
				(err: grpcWeb.Error, res: CreateBookingRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	updateBooking(req: UpdateBookingReq.AsObject, metadata?: grpcWeb.Metadata): Promise<UpdateBookingRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = UpdateBookingReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.BookingsService/UpdateBooking',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoUpdateBooking,
				(err: grpcWeb.Error, res: UpdateBookingRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	deleteBooking(req: DeleteBookingReq.AsObject, metadata?: grpcWeb.Metadata): Promise<DeleteBookingRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = DeleteBookingReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.BookingsService/DeleteBooking',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoDeleteBooking,
				(err: grpcWeb.Error, res: DeleteBookingRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	deleteMyBooking(req: DeleteMyBookingReq.AsObject, metadata?: grpcWeb.Metadata): Promise<DeleteMyBookingRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = DeleteMyBookingReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.BookingsService/DeleteMyBooking',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoDeleteMyBooking,
				(err: grpcWeb.Error, res: DeleteMyBookingRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

}




export declare namespace ListBookingsReq {
	export type AsObject = {
		page?: core.PageQueryDTO.AsObject,
		projectId?: googleProtobufWrappers.StringValue.AsObject,
		resourceId?: googleProtobufWrappers.StringValue.AsObject,
		availabilityId?: googleProtobufWrappers.StringValue.AsObject,
	}
}

export class ListBookingsReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, ListBookingsReq.repeatedFields_, null);
	}


	getPage(): core.PageQueryDTO {
		return jspb.Message.getWrapperField(this, core.PageQueryDTO, 1);
	}

	setPage(value?: core.PageQueryDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	getProjectId(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 2);
	}

	setProjectId(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 2, value);
	}

	getResourceId(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 3);
	}

	setResourceId(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 3, value);
	}

	getAvailabilityId(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 4);
	}

	setAvailabilityId(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 4, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		ListBookingsReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): ListBookingsReq.AsObject {
		let f: any;
		return {
			page: (f = this.getPage()) && f.toObject(),
			projectId: (f = this.getProjectId()) && f.toObject(),
			resourceId: (f = this.getResourceId()) && f.toObject(),
			availabilityId: (f = this.getAvailabilityId()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: ListBookingsReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getPage();
		if (field1 != null) {
			writer.writeMessage(1, field1, core.PageQueryDTO.serializeBinaryToWriter);
		}
		const field2 = message.getProjectId();
		if (field2 != null) {
			writer.writeMessage(2, field2, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field3 = message.getResourceId();
		if (field3 != null) {
			writer.writeMessage(3, field3, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field4 = message.getAvailabilityId();
		if (field4 != null) {
			writer.writeMessage(4, field4, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): ListBookingsReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new ListBookingsReq();
		return ListBookingsReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: ListBookingsReq, reader: jspb.BinaryReader): ListBookingsReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new core.PageQueryDTO();
				reader.readMessage(field1, core.PageQueryDTO.deserializeBinaryFromReader);
				message.setPage(field1);
				break;
			case 2:
				const field2 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field2, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setProjectId(field2);
				break;
			case 3:
				const field3 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field3, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setResourceId(field3);
				break;
			case 4:
				const field4 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field4, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setAvailabilityId(field4);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace ListBookingsRes {
	export type AsObject = {
		page?: core.PageDTO.AsObject,
		items: Array<BookingDTO.AsObject>,
	}
}

export class ListBookingsRes extends jspb.Message {

	private static repeatedFields_ = [
		2,
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, ListBookingsRes.repeatedFields_, null);
	}


	getPage(): core.PageDTO {
		return jspb.Message.getWrapperField(this, core.PageDTO, 1);
	}

	setPage(value?: core.PageDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	getItems(): Array<BookingDTO> {
		return jspb.Message.getRepeatedWrapperField(this, BookingDTO, 2);
	}

	setItems(value: Array<BookingDTO>): void {
		(jspb.Message as any).setRepeatedWrapperField(this, 2, value);
	}

	addItems(value?: BookingDTO, index?: number): BookingDTO {
		return jspb.Message.addToRepeatedWrapperField(this, 2, value, BookingDTO, index);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		ListBookingsRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): ListBookingsRes.AsObject {
		let f: any;
		return {
			page: (f = this.getPage()) && f.toObject(),
			items: this.getItems().map((item) => item.toObject()),
		};
	}

	static serializeBinaryToWriter(message: ListBookingsRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getPage();
		if (field1 != null) {
			writer.writeMessage(1, field1, core.PageDTO.serializeBinaryToWriter);
		}
		const field2 = message.getItems();
		if (field2.length > 0) {
			writer.writeRepeatedMessage(2, field2, BookingDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): ListBookingsRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new ListBookingsRes();
		return ListBookingsRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: ListBookingsRes, reader: jspb.BinaryReader): ListBookingsRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new core.PageDTO();
				reader.readMessage(field1, core.PageDTO.deserializeBinaryFromReader);
				message.setPage(field1);
				break;
			case 2:
				const field2 = new BookingDTO();
				reader.readMessage(field2, BookingDTO.deserializeBinaryFromReader);
				message.addItems(field2);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace ListMyBookingsReq {
	export type AsObject = {
		page?: core.PageQueryDTO.AsObject,
		projectId?: googleProtobufWrappers.StringValue.AsObject,
	}
}

export class ListMyBookingsReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, ListMyBookingsReq.repeatedFields_, null);
	}


	getPage(): core.PageQueryDTO {
		return jspb.Message.getWrapperField(this, core.PageQueryDTO, 1);
	}

	setPage(value?: core.PageQueryDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	getProjectId(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 2);
	}

	setProjectId(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 2, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		ListMyBookingsReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): ListMyBookingsReq.AsObject {
		let f: any;
		return {
			page: (f = this.getPage()) && f.toObject(),
			projectId: (f = this.getProjectId()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: ListMyBookingsReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getPage();
		if (field1 != null) {
			writer.writeMessage(1, field1, core.PageQueryDTO.serializeBinaryToWriter);
		}
		const field2 = message.getProjectId();
		if (field2 != null) {
			writer.writeMessage(2, field2, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): ListMyBookingsReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new ListMyBookingsReq();
		return ListMyBookingsReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: ListMyBookingsReq, reader: jspb.BinaryReader): ListMyBookingsReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new core.PageQueryDTO();
				reader.readMessage(field1, core.PageQueryDTO.deserializeBinaryFromReader);
				message.setPage(field1);
				break;
			case 2:
				const field2 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field2, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setProjectId(field2);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace ListMyBookingsRes {
	export type AsObject = {
		page?: core.PageDTO.AsObject,
		items: Array<BookingDTO.AsObject>,
	}
}

export class ListMyBookingsRes extends jspb.Message {

	private static repeatedFields_ = [
		2,
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, ListMyBookingsRes.repeatedFields_, null);
	}


	getPage(): core.PageDTO {
		return jspb.Message.getWrapperField(this, core.PageDTO, 1);
	}

	setPage(value?: core.PageDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	getItems(): Array<BookingDTO> {
		return jspb.Message.getRepeatedWrapperField(this, BookingDTO, 2);
	}

	setItems(value: Array<BookingDTO>): void {
		(jspb.Message as any).setRepeatedWrapperField(this, 2, value);
	}

	addItems(value?: BookingDTO, index?: number): BookingDTO {
		return jspb.Message.addToRepeatedWrapperField(this, 2, value, BookingDTO, index);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		ListMyBookingsRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): ListMyBookingsRes.AsObject {
		let f: any;
		return {
			page: (f = this.getPage()) && f.toObject(),
			items: this.getItems().map((item) => item.toObject()),
		};
	}

	static serializeBinaryToWriter(message: ListMyBookingsRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getPage();
		if (field1 != null) {
			writer.writeMessage(1, field1, core.PageDTO.serializeBinaryToWriter);
		}
		const field2 = message.getItems();
		if (field2.length > 0) {
			writer.writeRepeatedMessage(2, field2, BookingDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): ListMyBookingsRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new ListMyBookingsRes();
		return ListMyBookingsRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: ListMyBookingsRes, reader: jspb.BinaryReader): ListMyBookingsRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new core.PageDTO();
				reader.readMessage(field1, core.PageDTO.deserializeBinaryFromReader);
				message.setPage(field1);
				break;
			case 2:
				const field2 = new BookingDTO();
				reader.readMessage(field2, BookingDTO.deserializeBinaryFromReader);
				message.addItems(field2);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateBookingReq {
	export type AsObject = {
		availabilityId: string,
		authTags?: core.AuthorizationTagsDTO.AsObject,
		ownerEmail?: googleProtobufWrappers.StringValue.AsObject,
		ownerFirstName?: googleProtobufWrappers.StringValue.AsObject,
		ownerLastName?: googleProtobufWrappers.StringValue.AsObject,
	}
}

export class CreateBookingReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateBookingReq.repeatedFields_, null);
	}


	getAvailabilityId(): string {return jspb.Message.getFieldWithDefault(this, 2, "");
	}

	setAvailabilityId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 2, value);
	}

	getAuthTags(): core.AuthorizationTagsDTO {
		return jspb.Message.getWrapperField(this, core.AuthorizationTagsDTO, 5);
	}

	setAuthTags(value?: core.AuthorizationTagsDTO): void {
		(jspb.Message as any).setWrapperField(this, 5, value);
	}

	getOwnerEmail(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 6);
	}

	setOwnerEmail(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 6, value);
	}

	getOwnerFirstName(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 7);
	}

	setOwnerFirstName(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 7, value);
	}

	getOwnerLastName(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 8);
	}

	setOwnerLastName(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 8, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateBookingReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateBookingReq.AsObject {
		let f: any;
		return {
			availabilityId: this.getAvailabilityId(),
			authTags: (f = this.getAuthTags()) && f.toObject(),
			ownerEmail: (f = this.getOwnerEmail()) && f.toObject(),
			ownerFirstName: (f = this.getOwnerFirstName()) && f.toObject(),
			ownerLastName: (f = this.getOwnerLastName()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: CreateBookingReq, writer: jspb.BinaryWriter): void {
		const field2 = message.getAvailabilityId();
		if (field2.length > 0) {
			writer.writeString(2, field2);
		}
		const field5 = message.getAuthTags();
		if (field5 != null) {
			writer.writeMessage(5, field5, core.AuthorizationTagsDTO.serializeBinaryToWriter);
		}
		const field6 = message.getOwnerEmail();
		if (field6 != null) {
			writer.writeMessage(6, field6, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field7 = message.getOwnerFirstName();
		if (field7 != null) {
			writer.writeMessage(7, field7, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field8 = message.getOwnerLastName();
		if (field8 != null) {
			writer.writeMessage(8, field8, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateBookingReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateBookingReq();
		return CreateBookingReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateBookingReq, reader: jspb.BinaryReader): CreateBookingReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 2:
				const field2 = reader.readString()
				message.setAvailabilityId(field2);
				break;
			case 5:
				const field5 = new core.AuthorizationTagsDTO();
				reader.readMessage(field5, core.AuthorizationTagsDTO.deserializeBinaryFromReader);
				message.setAuthTags(field5);
				break;
			case 6:
				const field6 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field6, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setOwnerEmail(field6);
				break;
			case 7:
				const field7 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field7, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setOwnerFirstName(field7);
				break;
			case 8:
				const field8 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field8, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setOwnerLastName(field8);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateBookingRes {
	export type AsObject = {
		booking?: BookingDTO.AsObject,
	}
}

export class CreateBookingRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateBookingRes.repeatedFields_, null);
	}


	getBooking(): BookingDTO {
		return jspb.Message.getWrapperField(this, BookingDTO, 1);
	}

	setBooking(value?: BookingDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateBookingRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateBookingRes.AsObject {
		let f: any;
		return {
			booking: (f = this.getBooking()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: CreateBookingRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getBooking();
		if (field1 != null) {
			writer.writeMessage(1, field1, BookingDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateBookingRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateBookingRes();
		return CreateBookingRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateBookingRes, reader: jspb.BinaryReader): CreateBookingRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new BookingDTO();
				reader.readMessage(field1, BookingDTO.deserializeBinaryFromReader);
				message.setBooking(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace UpdateBookingReq {
	export type AsObject = {
		id: string,
	}
}

export class UpdateBookingReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, UpdateBookingReq.repeatedFields_, null);
	}


	getId(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		UpdateBookingReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): UpdateBookingReq.AsObject {
		let f: any;
		return {
			id: this.getId(),
		};
	}

	static serializeBinaryToWriter(message: UpdateBookingReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): UpdateBookingReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new UpdateBookingReq();
		return UpdateBookingReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: UpdateBookingReq, reader: jspb.BinaryReader): UpdateBookingReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setId(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace UpdateBookingRes {
	export type AsObject = {
		booking?: BookingDTO.AsObject,
	}
}

export class UpdateBookingRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, UpdateBookingRes.repeatedFields_, null);
	}


	getBooking(): BookingDTO {
		return jspb.Message.getWrapperField(this, BookingDTO, 1);
	}

	setBooking(value?: BookingDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		UpdateBookingRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): UpdateBookingRes.AsObject {
		let f: any;
		return {
			booking: (f = this.getBooking()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: UpdateBookingRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getBooking();
		if (field1 != null) {
			writer.writeMessage(1, field1, BookingDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): UpdateBookingRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new UpdateBookingRes();
		return UpdateBookingRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: UpdateBookingRes, reader: jspb.BinaryReader): UpdateBookingRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new BookingDTO();
				reader.readMessage(field1, BookingDTO.deserializeBinaryFromReader);
				message.setBooking(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace DeleteBookingReq {
	export type AsObject = {
		id: string,
	}
}

export class DeleteBookingReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, DeleteBookingReq.repeatedFields_, null);
	}


	getId(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		DeleteBookingReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): DeleteBookingReq.AsObject {
		let f: any;
		return {
			id: this.getId(),
		};
	}

	static serializeBinaryToWriter(message: DeleteBookingReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): DeleteBookingReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new DeleteBookingReq();
		return DeleteBookingReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: DeleteBookingReq, reader: jspb.BinaryReader): DeleteBookingReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setId(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace DeleteBookingRes {
	export type AsObject = {
	}
}

export class DeleteBookingRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, DeleteBookingRes.repeatedFields_, null);
	}


	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		DeleteBookingRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): DeleteBookingRes.AsObject {
		let f: any;
		return {
		};
	}

	static serializeBinaryToWriter(message: DeleteBookingRes, writer: jspb.BinaryWriter): void {
	}

	static deserializeBinary(bytes: Uint8Array): DeleteBookingRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new DeleteBookingRes();
		return DeleteBookingRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: DeleteBookingRes, reader: jspb.BinaryReader): DeleteBookingRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace DeleteMyBookingReq {
	export type AsObject = {
		id: string,
	}
}

export class DeleteMyBookingReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, DeleteMyBookingReq.repeatedFields_, null);
	}


	getId(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		DeleteMyBookingReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): DeleteMyBookingReq.AsObject {
		let f: any;
		return {
			id: this.getId(),
		};
	}

	static serializeBinaryToWriter(message: DeleteMyBookingReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): DeleteMyBookingReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new DeleteMyBookingReq();
		return DeleteMyBookingReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: DeleteMyBookingReq, reader: jspb.BinaryReader): DeleteMyBookingReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setId(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace DeleteMyBookingRes {
	export type AsObject = {
	}
}

export class DeleteMyBookingRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, DeleteMyBookingRes.repeatedFields_, null);
	}


	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		DeleteMyBookingRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): DeleteMyBookingRes.AsObject {
		let f: any;
		return {
		};
	}

	static serializeBinaryToWriter(message: DeleteMyBookingRes, writer: jspb.BinaryWriter): void {
	}

	static deserializeBinary(bytes: Uint8Array): DeleteMyBookingRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new DeleteMyBookingRes();
		return DeleteMyBookingRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: DeleteMyBookingRes, reader: jspb.BinaryReader): DeleteMyBookingRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace BookingDTO {
	export type AsObject = {
		id: string,
		metadata?: core.MetadataDTO.AsObject,
		ownerId: string,
		availabilityId: string,
		ownerEmail: string,
		ownerFirstName: string,
		ownerLastName: string,
	}
}

export class BookingDTO extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, BookingDTO.repeatedFields_, null);
	}


	getId(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	getMetadata(): core.MetadataDTO {
		return jspb.Message.getWrapperField(this, core.MetadataDTO, 2);
	}

	setMetadata(value?: core.MetadataDTO): void {
		(jspb.Message as any).setWrapperField(this, 2, value);
	}

	getOwnerId(): string {return jspb.Message.getFieldWithDefault(this, 3, "");
	}

	setOwnerId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 3, value);
	}

	getAvailabilityId(): string {return jspb.Message.getFieldWithDefault(this, 4, "");
	}

	setAvailabilityId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 4, value);
	}

	getOwnerEmail(): string {return jspb.Message.getFieldWithDefault(this, 5, "");
	}

	setOwnerEmail(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 5, value);
	}

	getOwnerFirstName(): string {return jspb.Message.getFieldWithDefault(this, 6, "");
	}

	setOwnerFirstName(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 6, value);
	}

	getOwnerLastName(): string {return jspb.Message.getFieldWithDefault(this, 7, "");
	}

	setOwnerLastName(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 7, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		BookingDTO.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): BookingDTO.AsObject {
		let f: any;
		return {
			id: this.getId(),
			metadata: (f = this.getMetadata()) && f.toObject(),
			ownerId: this.getOwnerId(),
			availabilityId: this.getAvailabilityId(),
			ownerEmail: this.getOwnerEmail(),
			ownerFirstName: this.getOwnerFirstName(),
			ownerLastName: this.getOwnerLastName(),
		};
	}

	static serializeBinaryToWriter(message: BookingDTO, writer: jspb.BinaryWriter): void {
		const field1 = message.getId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
		const field2 = message.getMetadata();
		if (field2 != null) {
			writer.writeMessage(2, field2, core.MetadataDTO.serializeBinaryToWriter);
		}
		const field3 = message.getOwnerId();
		if (field3.length > 0) {
			writer.writeString(3, field3);
		}
		const field4 = message.getAvailabilityId();
		if (field4.length > 0) {
			writer.writeString(4, field4);
		}
		const field5 = message.getOwnerEmail();
		if (field5.length > 0) {
			writer.writeString(5, field5);
		}
		const field6 = message.getOwnerFirstName();
		if (field6.length > 0) {
			writer.writeString(6, field6);
		}
		const field7 = message.getOwnerLastName();
		if (field7.length > 0) {
			writer.writeString(7, field7);
		}
	}

	static deserializeBinary(bytes: Uint8Array): BookingDTO {
		var reader = new jspb.BinaryReader(bytes);
		var message = new BookingDTO();
		return BookingDTO.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: BookingDTO, reader: jspb.BinaryReader): BookingDTO {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setId(field1);
				break;
			case 2:
				const field2 = new core.MetadataDTO();
				reader.readMessage(field2, core.MetadataDTO.deserializeBinaryFromReader);
				message.setMetadata(field2);
				break;
			case 3:
				const field3 = reader.readString()
				message.setOwnerId(field3);
				break;
			case 4:
				const field4 = reader.readString()
				message.setAvailabilityId(field4);
				break;
			case 5:
				const field5 = reader.readString()
				message.setOwnerEmail(field5);
				break;
			case 6:
				const field6 = reader.readString()
				message.setOwnerFirstName(field6);
				break;
			case 7:
				const field7 = reader.readString()
				message.setOwnerLastName(field7);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}


function ListBookingsReqFromObject(obj: ListBookingsReq.AsObject | undefined): ListBookingsReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new ListBookingsReq();
	message.setPage(PageQueryDTOFromObject(obj.page));
	message.setProjectId(StringValueFromObject(obj.projectId));
	message.setResourceId(StringValueFromObject(obj.resourceId));
	message.setAvailabilityId(StringValueFromObject(obj.availabilityId));
	return message;
}

function PageQueryDTOFromObject(obj: core.PageQueryDTO.AsObject | undefined): core.PageQueryDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new core.PageQueryDTO();
	message.setPage(Int32ValueFromObject(obj.page));
	message.setPageSize(Int32ValueFromObject(obj.pageSize));
	return message;
}

function Int32ValueFromObject(obj: googleProtobufWrappers.Int32Value.AsObject | undefined): googleProtobufWrappers.Int32Value | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new googleProtobufWrappers.Int32Value();
	message.setValue(obj.value);
	return message;
}

function StringValueFromObject(obj: googleProtobufWrappers.StringValue.AsObject | undefined): googleProtobufWrappers.StringValue | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new googleProtobufWrappers.StringValue();
	message.setValue(obj.value);
	return message;
}

function ListBookingsResFromObject(obj: ListBookingsRes.AsObject | undefined): ListBookingsRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new ListBookingsRes();
	message.setPage(PageDTOFromObject(obj.page));
	(obj.items || [])
		.map((item) => BookingDTOFromObject(item))
		.forEach((item) => message.addItems(item));
	return message;
}

function PageDTOFromObject(obj: core.PageDTO.AsObject | undefined): core.PageDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new core.PageDTO();
	message.setPage(obj.page);
	message.setPageSize(obj.pageSize);
	message.setTotalCount(obj.totalCount);
	return message;
}

function BookingDTOFromObject(obj: BookingDTO.AsObject | undefined): BookingDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new BookingDTO();
	message.setId(obj.id);
	message.setMetadata(MetadataDTOFromObject(obj.metadata));
	message.setOwnerId(obj.ownerId);
	message.setAvailabilityId(obj.availabilityId);
	message.setOwnerEmail(obj.ownerEmail);
	message.setOwnerFirstName(obj.ownerFirstName);
	message.setOwnerLastName(obj.ownerLastName);
	return message;
}

function MetadataDTOFromObject(obj: core.MetadataDTO.AsObject | undefined): core.MetadataDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new core.MetadataDTO();
	message.setCreatedAt(TimestampFromObject(obj.createdAt));
	message.setUpdatedAt(TimestampFromObject(obj.updatedAt));
	return message;
}

function TimestampFromObject(obj: googleProtobufTimestamp.Timestamp.AsObject | undefined): googleProtobufTimestamp.Timestamp | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new googleProtobufTimestamp.Timestamp();
	message.setSeconds(obj.seconds);
	message.setNanos(obj.nanos);
	return message;
}

function ListMyBookingsReqFromObject(obj: ListMyBookingsReq.AsObject | undefined): ListMyBookingsReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new ListMyBookingsReq();
	message.setPage(PageQueryDTOFromObject(obj.page));
	message.setProjectId(StringValueFromObject(obj.projectId));
	return message;
}

function ListMyBookingsResFromObject(obj: ListMyBookingsRes.AsObject | undefined): ListMyBookingsRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new ListMyBookingsRes();
	message.setPage(PageDTOFromObject(obj.page));
	(obj.items || [])
		.map((item) => BookingDTOFromObject(item))
		.forEach((item) => message.addItems(item));
	return message;
}

function CreateBookingReqFromObject(obj: CreateBookingReq.AsObject | undefined): CreateBookingReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateBookingReq();
	message.setAvailabilityId(obj.availabilityId);
	message.setAuthTags(AuthorizationTagsDTOFromObject(obj.authTags));
	message.setOwnerEmail(StringValueFromObject(obj.ownerEmail));
	message.setOwnerFirstName(StringValueFromObject(obj.ownerFirstName));
	message.setOwnerLastName(StringValueFromObject(obj.ownerLastName));
	return message;
}

function AuthorizationTagsDTOFromObject(obj: core.AuthorizationTagsDTO.AsObject | undefined): core.AuthorizationTagsDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new core.AuthorizationTagsDTO();
	(obj.tagsMap || [])
		.forEach((entry) => message.getTagsMap().set(entry[0], entry[1]));
	return message;
}

function CreateBookingResFromObject(obj: CreateBookingRes.AsObject | undefined): CreateBookingRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateBookingRes();
	message.setBooking(BookingDTOFromObject(obj.booking));
	return message;
}

function UpdateBookingReqFromObject(obj: UpdateBookingReq.AsObject | undefined): UpdateBookingReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new UpdateBookingReq();
	message.setId(obj.id);
	return message;
}

function UpdateBookingResFromObject(obj: UpdateBookingRes.AsObject | undefined): UpdateBookingRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new UpdateBookingRes();
	message.setBooking(BookingDTOFromObject(obj.booking));
	return message;
}

function DeleteBookingReqFromObject(obj: DeleteBookingReq.AsObject | undefined): DeleteBookingReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new DeleteBookingReq();
	message.setId(obj.id);
	return message;
}

function DeleteBookingResFromObject(obj: DeleteBookingRes.AsObject | undefined): DeleteBookingRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new DeleteBookingRes();
	return message;
}

function DeleteMyBookingReqFromObject(obj: DeleteMyBookingReq.AsObject | undefined): DeleteMyBookingReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new DeleteMyBookingReq();
	message.setId(obj.id);
	return message;
}

function DeleteMyBookingResFromObject(obj: DeleteMyBookingRes.AsObject | undefined): DeleteMyBookingRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new DeleteMyBookingRes();
	return message;
}

