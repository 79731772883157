import qs from 'query-string';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { EventsListWidget } from './EventsListWidget';
import { EventsListWidgetDTO } from '../../Lib/sdk/Widgets_pb';
import { CreateEventWidgetDTO } from '../../Lib/sdk/Widgets_pb';
import { CreateEventsManagementWidgetDTO } from '../../Lib/sdk/Widgets_pb';
import { sharedUserService } from '../../Lib/authentication/UserService';
import { WidgetTypeDTO } from '../../Lib/sdk/Widgets_pb';
import { CreateEventWidget } from './CreateEventWidget';
import { ManageEventsListWidget } from './ManageEventsListWidget';

const widgets: Record<
  WidgetTypeDTO,
  {
    parameters:
      | typeof EventsListWidgetDTO
      | typeof CreateEventWidgetDTO
      | typeof CreateEventsManagementWidgetDTO
      | undefined;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: (props: any) => JSX.Element | null;
  }
> = {
  [WidgetTypeDTO.WIDGET_TYPE_UNSPECIFIED]: {
    component: () => {
      return <h1>Page Not Found</h1>;
    },
    parameters: undefined,
  },
  [WidgetTypeDTO.WIDGET_TYPE_EVENTS_LIST]: {
    component: EventsListWidget,
    parameters: EventsListWidgetDTO,
  },
  [WidgetTypeDTO.WIDGET_TYPE_CREATE_EVENT]: {
    component: CreateEventWidget,
    parameters: CreateEventWidgetDTO,
  },
  [WidgetTypeDTO.WIDGET_TYPE_MANAGE_EVENTS_LIST]: {
    component: ManageEventsListWidget,
    parameters: CreateEventsManagementWidgetDTO,
  },
};

/**
 * This page acts as an entrypoint into the application
 * for deep links.
 *
 * The server can generate urls (i.e. deep links) that contain
 * a token + destination path.
 *
 * The token is optional.
 *
 * This deep linking workflow is used for fetching widgets for the user
 *
 * The expected url format is:
 * `https://domain.com/widgets/1?p=<params>#t=<token>`
 */

/**
 * the `p` (params) parameter will be a base64 encode string that will need to be deserialized
 * i.e. `?p=<params>`
 */
interface Props extends RouteComponentProps<void> {
  widgetType: Record<string, WidgetTypeDTO>;
}

export function StartPage(props: Props) {
  const { p: params } = qs.parse(window.location.search);
  const { t: token } = qs.parse(props.location.hash);

  const widgetType: WidgetTypeDTO = Number(props.widgetType.widgetType);
  if (!widgetType || !Object.values(WidgetTypeDTO).includes(widgetType)) {
    return <h1>Error</h1>;
  }
  const widget = widgets[widgetType];

  if (!widget.parameters || !widget.component) {
    return <h1>Error</h1>;
  }

  if (typeof params !== 'string') {
    return <h1>Error</h1>;
  }

  const WidgetProps = widget.parameters
    .deserializeBinary(Uint8Array.from(atob(params), (c) => c.charCodeAt(0)))
    .toObject();

  // // set the `t` (token) parameter as the current user's token
  // // i.e. login the browser in using the given token
  if (typeof token === 'string') {
    sharedUserService.signInToken(token);
  }

  // once the browser is logged in using the provided token
  // we can redirect the user to the `d` (destination) path
  return <widget.component {...WidgetProps} />;
}
