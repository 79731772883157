// Generated by protoc-gen-grpc-ts-web. DO NOT EDIT!
/* eslint-disable */
/* tslint:disable */

import * as jspb from 'google-protobuf';
import * as grpcWeb from 'grpc-web';

import * as googleProtobufTimestamp from 'google-protobuf/google/protobuf/timestamp_pb';
import * as googleProtobufWrappers from 'google-protobuf/google/protobuf/wrappers_pb';
import * as core from './Core_pb';
import * as projects from './Projects_pb';
import * as resources from './Resources_pb';

export class AvailabilitiesService {

	private client_ = new grpcWeb.GrpcWebClientBase({
		format: 'text',
	});

	private methodInfoListAvailabilities = new grpcWeb.MethodDescriptor<ListAvailabilitiesReq, ListAvailabilitiesRes>(
		"ListAvailabilities",
		null,
		ListAvailabilitiesReq,
		ListAvailabilitiesRes,
		(req: ListAvailabilitiesReq) => req.serializeBinary(),
		ListAvailabilitiesRes.deserializeBinary
	);

	private methodInfoCreateAvailability = new grpcWeb.MethodDescriptor<CreateAvailabilityReq, CreateAvailabilityRes>(
		"CreateAvailability",
		null,
		CreateAvailabilityReq,
		CreateAvailabilityRes,
		(req: CreateAvailabilityReq) => req.serializeBinary(),
		CreateAvailabilityRes.deserializeBinary
	);

	private methodInfoUpdateAvailability = new grpcWeb.MethodDescriptor<UpdateAvailabilityReq, UpdateAvailabilityRes>(
		"UpdateAvailability",
		null,
		UpdateAvailabilityReq,
		UpdateAvailabilityRes,
		(req: UpdateAvailabilityReq) => req.serializeBinary(),
		UpdateAvailabilityRes.deserializeBinary
	);

	private methodInfoDeleteAvailability = new grpcWeb.MethodDescriptor<DeleteAvailabilityReq, DeleteAvailabilityRes>(
		"DeleteAvailability",
		null,
		DeleteAvailabilityReq,
		DeleteAvailabilityRes,
		(req: DeleteAvailabilityReq) => req.serializeBinary(),
		DeleteAvailabilityRes.deserializeBinary
	);

	constructor(
		private hostname: string,
		private defaultMetadata?: () => grpcWeb.Metadata,
	) { }

	listAvailabilities(req: ListAvailabilitiesReq.AsObject, metadata?: grpcWeb.Metadata): Promise<ListAvailabilitiesRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = ListAvailabilitiesReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.AvailabilitiesService/ListAvailabilities',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoListAvailabilities,
				(err: grpcWeb.Error, res: ListAvailabilitiesRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	createAvailability(req: CreateAvailabilityReq.AsObject, metadata?: grpcWeb.Metadata): Promise<CreateAvailabilityRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = CreateAvailabilityReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.AvailabilitiesService/CreateAvailability',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoCreateAvailability,
				(err: grpcWeb.Error, res: CreateAvailabilityRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	updateAvailability(req: UpdateAvailabilityReq.AsObject, metadata?: grpcWeb.Metadata): Promise<UpdateAvailabilityRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = UpdateAvailabilityReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.AvailabilitiesService/UpdateAvailability',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoUpdateAvailability,
				(err: grpcWeb.Error, res: UpdateAvailabilityRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

	deleteAvailability(req: DeleteAvailabilityReq.AsObject, metadata?: grpcWeb.Metadata): Promise<DeleteAvailabilityRes.AsObject> {
		return new Promise((resolve, reject) => {
			const message = DeleteAvailabilityReqFromObject(req);
			this.client_.rpcCall(
				this.hostname + '/Timeslice.Sdk.AvailabilitiesService/DeleteAvailability',
				message,
				Object.assign({}, this.defaultMetadata ? this.defaultMetadata() : {}, metadata),
				this.methodInfoDeleteAvailability,
				(err: grpcWeb.Error, res: DeleteAvailabilityRes) => {
					if (err) {
						reject(err);
					} else {
						resolve(res.toObject());
					}
				},
			);
		});
	}

}

export enum FrequencyDTO {
	UNKNOWN = 0,
	DAILY = 1,
	WEEKLY = 2,
	FORTNIGHTLY = 3,
	MONTHLY = 4,
	QUARTERLY = 5,
	SEMIANNUALLY = 6,
	ANNUALLY = 7,
}
export enum AvailabilityFilterDTO {
	AVAILABILITY_FILTER_UNSPECIFIED = 0,
	AVAILABILITY_FILTER_ALL = 1,
	AVAILABILITY_FILTER_BOOKED = 2,
	AVAILABILITY_FILTER_NOT_BOOKED = 3,
}



export declare namespace ListAvailabilitiesReq {
	export type AsObject = {
		page?: core.PageQueryDTO.AsObject,
		projectId?: googleProtobufWrappers.StringValue.AsObject,
		resourceId?: googleProtobufWrappers.StringValue.AsObject,
		startTime?: googleProtobufTimestamp.Timestamp.AsObject,
		endTime?: googleProtobufTimestamp.Timestamp.AsObject,
	}
}

export class ListAvailabilitiesReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, ListAvailabilitiesReq.repeatedFields_, null);
	}


	getPage(): core.PageQueryDTO {
		return jspb.Message.getWrapperField(this, core.PageQueryDTO, 1);
	}

	setPage(value?: core.PageQueryDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	getProjectId(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 2);
	}

	setProjectId(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 2, value);
	}

	getResourceId(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 3);
	}

	setResourceId(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 3, value);
	}

	getStartTime(): googleProtobufTimestamp.Timestamp {
		return jspb.Message.getWrapperField(this, googleProtobufTimestamp.Timestamp, 4);
	}

	setStartTime(value?: googleProtobufTimestamp.Timestamp): void {
		(jspb.Message as any).setWrapperField(this, 4, value);
	}

	getEndTime(): googleProtobufTimestamp.Timestamp {
		return jspb.Message.getWrapperField(this, googleProtobufTimestamp.Timestamp, 5);
	}

	setEndTime(value?: googleProtobufTimestamp.Timestamp): void {
		(jspb.Message as any).setWrapperField(this, 5, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		ListAvailabilitiesReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): ListAvailabilitiesReq.AsObject {
		let f: any;
		return {
			page: (f = this.getPage()) && f.toObject(),
			projectId: (f = this.getProjectId()) && f.toObject(),
			resourceId: (f = this.getResourceId()) && f.toObject(),
			startTime: (f = this.getStartTime()) && f.toObject(),
			endTime: (f = this.getEndTime()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: ListAvailabilitiesReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getPage();
		if (field1 != null) {
			writer.writeMessage(1, field1, core.PageQueryDTO.serializeBinaryToWriter);
		}
		const field2 = message.getProjectId();
		if (field2 != null) {
			writer.writeMessage(2, field2, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field3 = message.getResourceId();
		if (field3 != null) {
			writer.writeMessage(3, field3, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field4 = message.getStartTime();
		if (field4 != null) {
			writer.writeMessage(4, field4, googleProtobufTimestamp.Timestamp.serializeBinaryToWriter);
		}
		const field5 = message.getEndTime();
		if (field5 != null) {
			writer.writeMessage(5, field5, googleProtobufTimestamp.Timestamp.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): ListAvailabilitiesReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new ListAvailabilitiesReq();
		return ListAvailabilitiesReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: ListAvailabilitiesReq, reader: jspb.BinaryReader): ListAvailabilitiesReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new core.PageQueryDTO();
				reader.readMessage(field1, core.PageQueryDTO.deserializeBinaryFromReader);
				message.setPage(field1);
				break;
			case 2:
				const field2 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field2, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setProjectId(field2);
				break;
			case 3:
				const field3 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field3, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setResourceId(field3);
				break;
			case 4:
				const field4 = new googleProtobufTimestamp.Timestamp();
				reader.readMessage(field4, googleProtobufTimestamp.Timestamp.deserializeBinaryFromReader);
				message.setStartTime(field4);
				break;
			case 5:
				const field5 = new googleProtobufTimestamp.Timestamp();
				reader.readMessage(field5, googleProtobufTimestamp.Timestamp.deserializeBinaryFromReader);
				message.setEndTime(field5);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace ListAvailabilitiesRes {
	export type AsObject = {
		page?: core.PageDTO.AsObject,
		items: Array<AvailabilityDTO.AsObject>,
	}
}

export class ListAvailabilitiesRes extends jspb.Message {

	private static repeatedFields_ = [
		4,
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, ListAvailabilitiesRes.repeatedFields_, null);
	}


	getPage(): core.PageDTO {
		return jspb.Message.getWrapperField(this, core.PageDTO, 1);
	}

	setPage(value?: core.PageDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	getItems(): Array<AvailabilityDTO> {
		return jspb.Message.getRepeatedWrapperField(this, AvailabilityDTO, 4);
	}

	setItems(value: Array<AvailabilityDTO>): void {
		(jspb.Message as any).setRepeatedWrapperField(this, 4, value);
	}

	addItems(value?: AvailabilityDTO, index?: number): AvailabilityDTO {
		return jspb.Message.addToRepeatedWrapperField(this, 4, value, AvailabilityDTO, index);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		ListAvailabilitiesRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): ListAvailabilitiesRes.AsObject {
		let f: any;
		return {
			page: (f = this.getPage()) && f.toObject(),
			items: this.getItems().map((item) => item.toObject()),
		};
	}

	static serializeBinaryToWriter(message: ListAvailabilitiesRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getPage();
		if (field1 != null) {
			writer.writeMessage(1, field1, core.PageDTO.serializeBinaryToWriter);
		}
		const field4 = message.getItems();
		if (field4.length > 0) {
			writer.writeRepeatedMessage(4, field4, AvailabilityDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): ListAvailabilitiesRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new ListAvailabilitiesRes();
		return ListAvailabilitiesRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: ListAvailabilitiesRes, reader: jspb.BinaryReader): ListAvailabilitiesRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new core.PageDTO();
				reader.readMessage(field1, core.PageDTO.deserializeBinaryFromReader);
				message.setPage(field1);
				break;
			case 4:
				const field4 = new AvailabilityDTO();
				reader.readMessage(field4, AvailabilityDTO.deserializeBinaryFromReader);
				message.addItems(field4);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateAvailabilityReq {
	export type AsObject = {
		startTime?: googleProtobufTimestamp.Timestamp.AsObject,
		endTime?: googleProtobufTimestamp.Timestamp.AsObject,
		maxParticipants?: googleProtobufWrappers.Int32Value.AsObject,
		resourceId: string,
		repeat?: RepeatDTO.AsObject,
		authTags?: core.AuthorizationTagsDTO.AsObject,
		location?: googleProtobufWrappers.StringValue.AsObject,
		url?: googleProtobufWrappers.StringValue.AsObject,
		color?: googleProtobufWrappers.StringValue.AsObject,
		description?: googleProtobufWrappers.StringValue.AsObject,
		cost?: googleProtobufWrappers.StringValue.AsObject,
		title?: googleProtobufWrappers.StringValue.AsObject,
		image?: googleProtobufWrappers.StringValue.AsObject,
		disableActions?: googleProtobufWrappers.BoolValue.AsObject,
	}
}

export class CreateAvailabilityReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateAvailabilityReq.repeatedFields_, null);
	}


	getStartTime(): googleProtobufTimestamp.Timestamp {
		return jspb.Message.getWrapperField(this, googleProtobufTimestamp.Timestamp, 1);
	}

	setStartTime(value?: googleProtobufTimestamp.Timestamp): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	getEndTime(): googleProtobufTimestamp.Timestamp {
		return jspb.Message.getWrapperField(this, googleProtobufTimestamp.Timestamp, 2);
	}

	setEndTime(value?: googleProtobufTimestamp.Timestamp): void {
		(jspb.Message as any).setWrapperField(this, 2, value);
	}

	getMaxParticipants(): googleProtobufWrappers.Int32Value {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.Int32Value, 3);
	}

	setMaxParticipants(value?: googleProtobufWrappers.Int32Value): void {
		(jspb.Message as any).setWrapperField(this, 3, value);
	}

	getResourceId(): string {return jspb.Message.getFieldWithDefault(this, 4, "");
	}

	setResourceId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 4, value);
	}

	getRepeat(): RepeatDTO {
		return jspb.Message.getWrapperField(this, RepeatDTO, 5);
	}

	setRepeat(value?: RepeatDTO): void {
		(jspb.Message as any).setWrapperField(this, 5, value);
	}

	getAuthTags(): core.AuthorizationTagsDTO {
		return jspb.Message.getWrapperField(this, core.AuthorizationTagsDTO, 6);
	}

	setAuthTags(value?: core.AuthorizationTagsDTO): void {
		(jspb.Message as any).setWrapperField(this, 6, value);
	}

	getLocation(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 7);
	}

	setLocation(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 7, value);
	}

	getUrl(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 8);
	}

	setUrl(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 8, value);
	}

	getColor(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 9);
	}

	setColor(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 9, value);
	}

	getDescription(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 10);
	}

	setDescription(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 10, value);
	}

	getCost(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 11);
	}

	setCost(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 11, value);
	}

	getTitle(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 12);
	}

	setTitle(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 12, value);
	}

	getImage(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 13);
	}

	setImage(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 13, value);
	}

	getDisableActions(): googleProtobufWrappers.BoolValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.BoolValue, 14);
	}

	setDisableActions(value?: googleProtobufWrappers.BoolValue): void {
		(jspb.Message as any).setWrapperField(this, 14, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateAvailabilityReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateAvailabilityReq.AsObject {
		let f: any;
		return {
			startTime: (f = this.getStartTime()) && f.toObject(),
			endTime: (f = this.getEndTime()) && f.toObject(),
			maxParticipants: (f = this.getMaxParticipants()) && f.toObject(),
			resourceId: this.getResourceId(),
			repeat: (f = this.getRepeat()) && f.toObject(),
			authTags: (f = this.getAuthTags()) && f.toObject(),
			location: (f = this.getLocation()) && f.toObject(),
			url: (f = this.getUrl()) && f.toObject(),
			color: (f = this.getColor()) && f.toObject(),
			description: (f = this.getDescription()) && f.toObject(),
			cost: (f = this.getCost()) && f.toObject(),
			title: (f = this.getTitle()) && f.toObject(),
			image: (f = this.getImage()) && f.toObject(),
			disableActions: (f = this.getDisableActions()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: CreateAvailabilityReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getStartTime();
		if (field1 != null) {
			writer.writeMessage(1, field1, googleProtobufTimestamp.Timestamp.serializeBinaryToWriter);
		}
		const field2 = message.getEndTime();
		if (field2 != null) {
			writer.writeMessage(2, field2, googleProtobufTimestamp.Timestamp.serializeBinaryToWriter);
		}
		const field3 = message.getMaxParticipants();
		if (field3 != null) {
			writer.writeMessage(3, field3, googleProtobufWrappers.Int32Value.serializeBinaryToWriter);
		}
		const field4 = message.getResourceId();
		if (field4.length > 0) {
			writer.writeString(4, field4);
		}
		const field5 = message.getRepeat();
		if (field5 != null) {
			writer.writeMessage(5, field5, RepeatDTO.serializeBinaryToWriter);
		}
		const field6 = message.getAuthTags();
		if (field6 != null) {
			writer.writeMessage(6, field6, core.AuthorizationTagsDTO.serializeBinaryToWriter);
		}
		const field7 = message.getLocation();
		if (field7 != null) {
			writer.writeMessage(7, field7, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field8 = message.getUrl();
		if (field8 != null) {
			writer.writeMessage(8, field8, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field9 = message.getColor();
		if (field9 != null) {
			writer.writeMessage(9, field9, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field10 = message.getDescription();
		if (field10 != null) {
			writer.writeMessage(10, field10, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field11 = message.getCost();
		if (field11 != null) {
			writer.writeMessage(11, field11, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field12 = message.getTitle();
		if (field12 != null) {
			writer.writeMessage(12, field12, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field13 = message.getImage();
		if (field13 != null) {
			writer.writeMessage(13, field13, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field14 = message.getDisableActions();
		if (field14 != null) {
			writer.writeMessage(14, field14, googleProtobufWrappers.BoolValue.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateAvailabilityReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateAvailabilityReq();
		return CreateAvailabilityReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateAvailabilityReq, reader: jspb.BinaryReader): CreateAvailabilityReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new googleProtobufTimestamp.Timestamp();
				reader.readMessage(field1, googleProtobufTimestamp.Timestamp.deserializeBinaryFromReader);
				message.setStartTime(field1);
				break;
			case 2:
				const field2 = new googleProtobufTimestamp.Timestamp();
				reader.readMessage(field2, googleProtobufTimestamp.Timestamp.deserializeBinaryFromReader);
				message.setEndTime(field2);
				break;
			case 3:
				const field3 = new googleProtobufWrappers.Int32Value();
				reader.readMessage(field3, googleProtobufWrappers.Int32Value.deserializeBinaryFromReader);
				message.setMaxParticipants(field3);
				break;
			case 4:
				const field4 = reader.readString()
				message.setResourceId(field4);
				break;
			case 5:
				const field5 = new RepeatDTO();
				reader.readMessage(field5, RepeatDTO.deserializeBinaryFromReader);
				message.setRepeat(field5);
				break;
			case 6:
				const field6 = new core.AuthorizationTagsDTO();
				reader.readMessage(field6, core.AuthorizationTagsDTO.deserializeBinaryFromReader);
				message.setAuthTags(field6);
				break;
			case 7:
				const field7 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field7, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setLocation(field7);
				break;
			case 8:
				const field8 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field8, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setUrl(field8);
				break;
			case 9:
				const field9 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field9, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setColor(field9);
				break;
			case 10:
				const field10 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field10, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setDescription(field10);
				break;
			case 11:
				const field11 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field11, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setCost(field11);
				break;
			case 12:
				const field12 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field12, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setTitle(field12);
				break;
			case 13:
				const field13 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field13, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setImage(field13);
				break;
			case 14:
				const field14 = new googleProtobufWrappers.BoolValue();
				reader.readMessage(field14, googleProtobufWrappers.BoolValue.deserializeBinaryFromReader);
				message.setDisableActions(field14);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace CreateAvailabilityRes {
	export type AsObject = {
		availability?: AvailabilityDTO.AsObject,
	}
}

export class CreateAvailabilityRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, CreateAvailabilityRes.repeatedFields_, null);
	}


	getAvailability(): AvailabilityDTO {
		return jspb.Message.getWrapperField(this, AvailabilityDTO, 1);
	}

	setAvailability(value?: AvailabilityDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		CreateAvailabilityRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): CreateAvailabilityRes.AsObject {
		let f: any;
		return {
			availability: (f = this.getAvailability()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: CreateAvailabilityRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getAvailability();
		if (field1 != null) {
			writer.writeMessage(1, field1, AvailabilityDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): CreateAvailabilityRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new CreateAvailabilityRes();
		return CreateAvailabilityRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: CreateAvailabilityRes, reader: jspb.BinaryReader): CreateAvailabilityRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new AvailabilityDTO();
				reader.readMessage(field1, AvailabilityDTO.deserializeBinaryFromReader);
				message.setAvailability(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace UpdateAvailabilityReq {
	export type AsObject = {
		id: string,
		startTime?: googleProtobufTimestamp.Timestamp.AsObject,
		endTime?: googleProtobufTimestamp.Timestamp.AsObject,
		maxParticipants?: googleProtobufWrappers.Int32Value.AsObject,
		repeat?: RepeatDTO.AsObject,
		location?: googleProtobufWrappers.StringValue.AsObject,
		url?: googleProtobufWrappers.StringValue.AsObject,
		color?: googleProtobufWrappers.StringValue.AsObject,
		description?: googleProtobufWrappers.StringValue.AsObject,
		cost?: googleProtobufWrappers.StringValue.AsObject,
		title?: googleProtobufWrappers.StringValue.AsObject,
		image?: googleProtobufWrappers.StringValue.AsObject,
		disableActions?: googleProtobufWrappers.BoolValue.AsObject,
	}
}

export class UpdateAvailabilityReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, UpdateAvailabilityReq.repeatedFields_, null);
	}


	getId(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	getStartTime(): googleProtobufTimestamp.Timestamp {
		return jspb.Message.getWrapperField(this, googleProtobufTimestamp.Timestamp, 2);
	}

	setStartTime(value?: googleProtobufTimestamp.Timestamp): void {
		(jspb.Message as any).setWrapperField(this, 2, value);
	}

	getEndTime(): googleProtobufTimestamp.Timestamp {
		return jspb.Message.getWrapperField(this, googleProtobufTimestamp.Timestamp, 3);
	}

	setEndTime(value?: googleProtobufTimestamp.Timestamp): void {
		(jspb.Message as any).setWrapperField(this, 3, value);
	}

	getMaxParticipants(): googleProtobufWrappers.Int32Value {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.Int32Value, 4);
	}

	setMaxParticipants(value?: googleProtobufWrappers.Int32Value): void {
		(jspb.Message as any).setWrapperField(this, 4, value);
	}

	getRepeat(): RepeatDTO {
		return jspb.Message.getWrapperField(this, RepeatDTO, 5);
	}

	setRepeat(value?: RepeatDTO): void {
		(jspb.Message as any).setWrapperField(this, 5, value);
	}

	getLocation(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 6);
	}

	setLocation(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 6, value);
	}

	getUrl(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 7);
	}

	setUrl(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 7, value);
	}

	getColor(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 8);
	}

	setColor(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 8, value);
	}

	getDescription(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 9);
	}

	setDescription(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 9, value);
	}

	getCost(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 10);
	}

	setCost(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 10, value);
	}

	getTitle(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 11);
	}

	setTitle(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 11, value);
	}

	getImage(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 12);
	}

	setImage(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 12, value);
	}

	getDisableActions(): googleProtobufWrappers.BoolValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.BoolValue, 13);
	}

	setDisableActions(value?: googleProtobufWrappers.BoolValue): void {
		(jspb.Message as any).setWrapperField(this, 13, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		UpdateAvailabilityReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): UpdateAvailabilityReq.AsObject {
		let f: any;
		return {
			id: this.getId(),
			startTime: (f = this.getStartTime()) && f.toObject(),
			endTime: (f = this.getEndTime()) && f.toObject(),
			maxParticipants: (f = this.getMaxParticipants()) && f.toObject(),
			repeat: (f = this.getRepeat()) && f.toObject(),
			location: (f = this.getLocation()) && f.toObject(),
			url: (f = this.getUrl()) && f.toObject(),
			color: (f = this.getColor()) && f.toObject(),
			description: (f = this.getDescription()) && f.toObject(),
			cost: (f = this.getCost()) && f.toObject(),
			title: (f = this.getTitle()) && f.toObject(),
			image: (f = this.getImage()) && f.toObject(),
			disableActions: (f = this.getDisableActions()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: UpdateAvailabilityReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
		const field2 = message.getStartTime();
		if (field2 != null) {
			writer.writeMessage(2, field2, googleProtobufTimestamp.Timestamp.serializeBinaryToWriter);
		}
		const field3 = message.getEndTime();
		if (field3 != null) {
			writer.writeMessage(3, field3, googleProtobufTimestamp.Timestamp.serializeBinaryToWriter);
		}
		const field4 = message.getMaxParticipants();
		if (field4 != null) {
			writer.writeMessage(4, field4, googleProtobufWrappers.Int32Value.serializeBinaryToWriter);
		}
		const field5 = message.getRepeat();
		if (field5 != null) {
			writer.writeMessage(5, field5, RepeatDTO.serializeBinaryToWriter);
		}
		const field6 = message.getLocation();
		if (field6 != null) {
			writer.writeMessage(6, field6, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field7 = message.getUrl();
		if (field7 != null) {
			writer.writeMessage(7, field7, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field8 = message.getColor();
		if (field8 != null) {
			writer.writeMessage(8, field8, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field9 = message.getDescription();
		if (field9 != null) {
			writer.writeMessage(9, field9, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field10 = message.getCost();
		if (field10 != null) {
			writer.writeMessage(10, field10, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field11 = message.getTitle();
		if (field11 != null) {
			writer.writeMessage(11, field11, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field12 = message.getImage();
		if (field12 != null) {
			writer.writeMessage(12, field12, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field13 = message.getDisableActions();
		if (field13 != null) {
			writer.writeMessage(13, field13, googleProtobufWrappers.BoolValue.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): UpdateAvailabilityReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new UpdateAvailabilityReq();
		return UpdateAvailabilityReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: UpdateAvailabilityReq, reader: jspb.BinaryReader): UpdateAvailabilityReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setId(field1);
				break;
			case 2:
				const field2 = new googleProtobufTimestamp.Timestamp();
				reader.readMessage(field2, googleProtobufTimestamp.Timestamp.deserializeBinaryFromReader);
				message.setStartTime(field2);
				break;
			case 3:
				const field3 = new googleProtobufTimestamp.Timestamp();
				reader.readMessage(field3, googleProtobufTimestamp.Timestamp.deserializeBinaryFromReader);
				message.setEndTime(field3);
				break;
			case 4:
				const field4 = new googleProtobufWrappers.Int32Value();
				reader.readMessage(field4, googleProtobufWrappers.Int32Value.deserializeBinaryFromReader);
				message.setMaxParticipants(field4);
				break;
			case 5:
				const field5 = new RepeatDTO();
				reader.readMessage(field5, RepeatDTO.deserializeBinaryFromReader);
				message.setRepeat(field5);
				break;
			case 6:
				const field6 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field6, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setLocation(field6);
				break;
			case 7:
				const field7 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field7, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setUrl(field7);
				break;
			case 8:
				const field8 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field8, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setColor(field8);
				break;
			case 9:
				const field9 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field9, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setDescription(field9);
				break;
			case 10:
				const field10 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field10, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setCost(field10);
				break;
			case 11:
				const field11 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field11, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setTitle(field11);
				break;
			case 12:
				const field12 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field12, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setImage(field12);
				break;
			case 13:
				const field13 = new googleProtobufWrappers.BoolValue();
				reader.readMessage(field13, googleProtobufWrappers.BoolValue.deserializeBinaryFromReader);
				message.setDisableActions(field13);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace UpdateAvailabilityRes {
	export type AsObject = {
		availability?: AvailabilityDTO.AsObject,
	}
}

export class UpdateAvailabilityRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, UpdateAvailabilityRes.repeatedFields_, null);
	}


	getAvailability(): AvailabilityDTO {
		return jspb.Message.getWrapperField(this, AvailabilityDTO, 1);
	}

	setAvailability(value?: AvailabilityDTO): void {
		(jspb.Message as any).setWrapperField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		UpdateAvailabilityRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): UpdateAvailabilityRes.AsObject {
		let f: any;
		return {
			availability: (f = this.getAvailability()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: UpdateAvailabilityRes, writer: jspb.BinaryWriter): void {
		const field1 = message.getAvailability();
		if (field1 != null) {
			writer.writeMessage(1, field1, AvailabilityDTO.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): UpdateAvailabilityRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new UpdateAvailabilityRes();
		return UpdateAvailabilityRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: UpdateAvailabilityRes, reader: jspb.BinaryReader): UpdateAvailabilityRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = new AvailabilityDTO();
				reader.readMessage(field1, AvailabilityDTO.deserializeBinaryFromReader);
				message.setAvailability(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace DeleteAvailabilityReq {
	export type AsObject = {
		id: string,
	}
}

export class DeleteAvailabilityReq extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, DeleteAvailabilityReq.repeatedFields_, null);
	}


	getId(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		DeleteAvailabilityReq.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): DeleteAvailabilityReq.AsObject {
		let f: any;
		return {
			id: this.getId(),
		};
	}

	static serializeBinaryToWriter(message: DeleteAvailabilityReq, writer: jspb.BinaryWriter): void {
		const field1 = message.getId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
	}

	static deserializeBinary(bytes: Uint8Array): DeleteAvailabilityReq {
		var reader = new jspb.BinaryReader(bytes);
		var message = new DeleteAvailabilityReq();
		return DeleteAvailabilityReq.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: DeleteAvailabilityReq, reader: jspb.BinaryReader): DeleteAvailabilityReq {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setId(field1);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace DeleteAvailabilityRes {
	export type AsObject = {
	}
}

export class DeleteAvailabilityRes extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, DeleteAvailabilityRes.repeatedFields_, null);
	}


	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		DeleteAvailabilityRes.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): DeleteAvailabilityRes.AsObject {
		let f: any;
		return {
		};
	}

	static serializeBinaryToWriter(message: DeleteAvailabilityRes, writer: jspb.BinaryWriter): void {
	}

	static deserializeBinary(bytes: Uint8Array): DeleteAvailabilityRes {
		var reader = new jspb.BinaryReader(bytes);
		var message = new DeleteAvailabilityRes();
		return DeleteAvailabilityRes.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: DeleteAvailabilityRes, reader: jspb.BinaryReader): DeleteAvailabilityRes {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace AvailabilityDTO {
	export type AsObject = {
		id: string,
		metadata?: core.MetadataDTO.AsObject,
		startTime?: googleProtobufTimestamp.Timestamp.AsObject,
		endTime?: googleProtobufTimestamp.Timestamp.AsObject,
		maxParticipants: number,
		resource?: resources.ResourceDTO.AsObject,
		repeat?: RepeatDTO.AsObject,
		project?: projects.ProjectDTO.AsObject,
		location?: googleProtobufWrappers.StringValue.AsObject,
		url?: googleProtobufWrappers.StringValue.AsObject,
		color?: googleProtobufWrappers.StringValue.AsObject,
		description?: googleProtobufWrappers.StringValue.AsObject,
		cost?: googleProtobufWrappers.StringValue.AsObject,
		title?: googleProtobufWrappers.StringValue.AsObject,
		image?: googleProtobufWrappers.StringValue.AsObject,
		authTags?: core.AuthorizationTagsDTO.AsObject,
		disableActions: boolean,
	}
}

export class AvailabilityDTO extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, AvailabilityDTO.repeatedFields_, null);
	}


	getId(): string {return jspb.Message.getFieldWithDefault(this, 1, "");
	}

	setId(value: string): void {
		(jspb.Message as any).setProto3StringField(this, 1, value);
	}

	getMetadata(): core.MetadataDTO {
		return jspb.Message.getWrapperField(this, core.MetadataDTO, 2);
	}

	setMetadata(value?: core.MetadataDTO): void {
		(jspb.Message as any).setWrapperField(this, 2, value);
	}

	getStartTime(): googleProtobufTimestamp.Timestamp {
		return jspb.Message.getWrapperField(this, googleProtobufTimestamp.Timestamp, 3);
	}

	setStartTime(value?: googleProtobufTimestamp.Timestamp): void {
		(jspb.Message as any).setWrapperField(this, 3, value);
	}

	getEndTime(): googleProtobufTimestamp.Timestamp {
		return jspb.Message.getWrapperField(this, googleProtobufTimestamp.Timestamp, 4);
	}

	setEndTime(value?: googleProtobufTimestamp.Timestamp): void {
		(jspb.Message as any).setWrapperField(this, 4, value);
	}

	getMaxParticipants(): number {return jspb.Message.getFieldWithDefault(this, 5, 0);
	}

	setMaxParticipants(value: number): void {
		(jspb.Message as any).setProto3IntField(this, 5, value);
	}

	getResource(): resources.ResourceDTO {
		return jspb.Message.getWrapperField(this, resources.ResourceDTO, 6);
	}

	setResource(value?: resources.ResourceDTO): void {
		(jspb.Message as any).setWrapperField(this, 6, value);
	}

	getRepeat(): RepeatDTO {
		return jspb.Message.getWrapperField(this, RepeatDTO, 7);
	}

	setRepeat(value?: RepeatDTO): void {
		(jspb.Message as any).setWrapperField(this, 7, value);
	}

	getProject(): projects.ProjectDTO {
		return jspb.Message.getWrapperField(this, projects.ProjectDTO, 8);
	}

	setProject(value?: projects.ProjectDTO): void {
		(jspb.Message as any).setWrapperField(this, 8, value);
	}

	getLocation(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 9);
	}

	setLocation(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 9, value);
	}

	getUrl(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 10);
	}

	setUrl(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 10, value);
	}

	getColor(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 11);
	}

	setColor(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 11, value);
	}

	getDescription(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 12);
	}

	setDescription(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 12, value);
	}

	getCost(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 13);
	}

	setCost(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 13, value);
	}

	getTitle(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 14);
	}

	setTitle(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 14, value);
	}

	getImage(): googleProtobufWrappers.StringValue {
		return jspb.Message.getWrapperField(this, googleProtobufWrappers.StringValue, 15);
	}

	setImage(value?: googleProtobufWrappers.StringValue): void {
		(jspb.Message as any).setWrapperField(this, 15, value);
	}

	getAuthTags(): core.AuthorizationTagsDTO {
		return jspb.Message.getWrapperField(this, core.AuthorizationTagsDTO, 16);
	}

	setAuthTags(value?: core.AuthorizationTagsDTO): void {
		(jspb.Message as any).setWrapperField(this, 16, value);
	}

	getDisableActions(): boolean {return jspb.Message.getFieldWithDefault(this, 17, false);
	}

	setDisableActions(value: boolean): void {
		(jspb.Message as any).setProto3BooleanField(this, 17, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		AvailabilityDTO.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): AvailabilityDTO.AsObject {
		let f: any;
		return {
			id: this.getId(),
			metadata: (f = this.getMetadata()) && f.toObject(),
			startTime: (f = this.getStartTime()) && f.toObject(),
			endTime: (f = this.getEndTime()) && f.toObject(),
			maxParticipants: this.getMaxParticipants(),
			resource: (f = this.getResource()) && f.toObject(),
			repeat: (f = this.getRepeat()) && f.toObject(),
			project: (f = this.getProject()) && f.toObject(),
			location: (f = this.getLocation()) && f.toObject(),
			url: (f = this.getUrl()) && f.toObject(),
			color: (f = this.getColor()) && f.toObject(),
			description: (f = this.getDescription()) && f.toObject(),
			cost: (f = this.getCost()) && f.toObject(),
			title: (f = this.getTitle()) && f.toObject(),
			image: (f = this.getImage()) && f.toObject(),
			authTags: (f = this.getAuthTags()) && f.toObject(),
			disableActions: this.getDisableActions(),
		};
	}

	static serializeBinaryToWriter(message: AvailabilityDTO, writer: jspb.BinaryWriter): void {
		const field1 = message.getId();
		if (field1.length > 0) {
			writer.writeString(1, field1);
		}
		const field2 = message.getMetadata();
		if (field2 != null) {
			writer.writeMessage(2, field2, core.MetadataDTO.serializeBinaryToWriter);
		}
		const field3 = message.getStartTime();
		if (field3 != null) {
			writer.writeMessage(3, field3, googleProtobufTimestamp.Timestamp.serializeBinaryToWriter);
		}
		const field4 = message.getEndTime();
		if (field4 != null) {
			writer.writeMessage(4, field4, googleProtobufTimestamp.Timestamp.serializeBinaryToWriter);
		}
		const field5 = message.getMaxParticipants();
		if (field5 != 0) {
			writer.writeInt32(5, field5);
		}
		const field6 = message.getResource();
		if (field6 != null) {
			writer.writeMessage(6, field6, resources.ResourceDTO.serializeBinaryToWriter);
		}
		const field7 = message.getRepeat();
		if (field7 != null) {
			writer.writeMessage(7, field7, RepeatDTO.serializeBinaryToWriter);
		}
		const field8 = message.getProject();
		if (field8 != null) {
			writer.writeMessage(8, field8, projects.ProjectDTO.serializeBinaryToWriter);
		}
		const field9 = message.getLocation();
		if (field9 != null) {
			writer.writeMessage(9, field9, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field10 = message.getUrl();
		if (field10 != null) {
			writer.writeMessage(10, field10, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field11 = message.getColor();
		if (field11 != null) {
			writer.writeMessage(11, field11, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field12 = message.getDescription();
		if (field12 != null) {
			writer.writeMessage(12, field12, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field13 = message.getCost();
		if (field13 != null) {
			writer.writeMessage(13, field13, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field14 = message.getTitle();
		if (field14 != null) {
			writer.writeMessage(14, field14, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field15 = message.getImage();
		if (field15 != null) {
			writer.writeMessage(15, field15, googleProtobufWrappers.StringValue.serializeBinaryToWriter);
		}
		const field16 = message.getAuthTags();
		if (field16 != null) {
			writer.writeMessage(16, field16, core.AuthorizationTagsDTO.serializeBinaryToWriter);
		}
		const field17 = message.getDisableActions();
		if (field17 != false) {
			writer.writeBool(17, field17);
		}
	}

	static deserializeBinary(bytes: Uint8Array): AvailabilityDTO {
		var reader = new jspb.BinaryReader(bytes);
		var message = new AvailabilityDTO();
		return AvailabilityDTO.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: AvailabilityDTO, reader: jspb.BinaryReader): AvailabilityDTO {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readString()
				message.setId(field1);
				break;
			case 2:
				const field2 = new core.MetadataDTO();
				reader.readMessage(field2, core.MetadataDTO.deserializeBinaryFromReader);
				message.setMetadata(field2);
				break;
			case 3:
				const field3 = new googleProtobufTimestamp.Timestamp();
				reader.readMessage(field3, googleProtobufTimestamp.Timestamp.deserializeBinaryFromReader);
				message.setStartTime(field3);
				break;
			case 4:
				const field4 = new googleProtobufTimestamp.Timestamp();
				reader.readMessage(field4, googleProtobufTimestamp.Timestamp.deserializeBinaryFromReader);
				message.setEndTime(field4);
				break;
			case 5:
				const field5 = reader.readInt32()
				message.setMaxParticipants(field5);
				break;
			case 6:
				const field6 = new resources.ResourceDTO();
				reader.readMessage(field6, resources.ResourceDTO.deserializeBinaryFromReader);
				message.setResource(field6);
				break;
			case 7:
				const field7 = new RepeatDTO();
				reader.readMessage(field7, RepeatDTO.deserializeBinaryFromReader);
				message.setRepeat(field7);
				break;
			case 8:
				const field8 = new projects.ProjectDTO();
				reader.readMessage(field8, projects.ProjectDTO.deserializeBinaryFromReader);
				message.setProject(field8);
				break;
			case 9:
				const field9 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field9, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setLocation(field9);
				break;
			case 10:
				const field10 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field10, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setUrl(field10);
				break;
			case 11:
				const field11 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field11, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setColor(field11);
				break;
			case 12:
				const field12 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field12, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setDescription(field12);
				break;
			case 13:
				const field13 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field13, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setCost(field13);
				break;
			case 14:
				const field14 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field14, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setTitle(field14);
				break;
			case 15:
				const field15 = new googleProtobufWrappers.StringValue();
				reader.readMessage(field15, googleProtobufWrappers.StringValue.deserializeBinaryFromReader);
				message.setImage(field15);
				break;
			case 16:
				const field16 = new core.AuthorizationTagsDTO();
				reader.readMessage(field16, core.AuthorizationTagsDTO.deserializeBinaryFromReader);
				message.setAuthTags(field16);
				break;
			case 17:
				const field17 = reader.readBool()
				message.setDisableActions(field17);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}
export declare namespace RepeatDTO {
	export type AsObject = {
		frequency: FrequencyDTO,
		endAfter?: googleProtobufTimestamp.Timestamp.AsObject,
	}
}

export class RepeatDTO extends jspb.Message {

	private static repeatedFields_ = [
		
	];

	constructor(data?: jspb.Message.MessageArray) {
		super();
		jspb.Message.initialize(this, data || [], 0, -1, RepeatDTO.repeatedFields_, null);
	}


	getFrequency(): FrequencyDTO {return jspb.Message.getFieldWithDefault(this, 1, 0);
	}

	setFrequency(value: FrequencyDTO): void {
		(jspb.Message as any).setProto3EnumField(this, 1, value);
	}

	getEndAfter(): googleProtobufTimestamp.Timestamp {
		return jspb.Message.getWrapperField(this, googleProtobufTimestamp.Timestamp, 2);
	}

	setEndAfter(value?: googleProtobufTimestamp.Timestamp): void {
		(jspb.Message as any).setWrapperField(this, 2, value);
	}

	serializeBinary(): Uint8Array {
		const writer = new jspb.BinaryWriter();
		RepeatDTO.serializeBinaryToWriter(this, writer);
		return writer.getResultBuffer();
	}

	toObject(): RepeatDTO.AsObject {
		let f: any;
		return {
			frequency: this.getFrequency(),
			endAfter: (f = this.getEndAfter()) && f.toObject(),
		};
	}

	static serializeBinaryToWriter(message: RepeatDTO, writer: jspb.BinaryWriter): void {
		const field1 = message.getFrequency();
		if (field1 != 0) {
			writer.writeEnum(1, field1);
		}
		const field2 = message.getEndAfter();
		if (field2 != null) {
			writer.writeMessage(2, field2, googleProtobufTimestamp.Timestamp.serializeBinaryToWriter);
		}
	}

	static deserializeBinary(bytes: Uint8Array): RepeatDTO {
		var reader = new jspb.BinaryReader(bytes);
		var message = new RepeatDTO();
		return RepeatDTO.deserializeBinaryFromReader(message, reader);
	}

	static deserializeBinaryFromReader(message: RepeatDTO, reader: jspb.BinaryReader): RepeatDTO {
		while (reader.nextField()) {
			if (reader.isEndGroup()) {
				break;
			}
			const field = reader.getFieldNumber();
			switch (field) {
			case 1:
				const field1 = reader.readEnum()
				message.setFrequency(field1);
				break;
			case 2:
				const field2 = new googleProtobufTimestamp.Timestamp();
				reader.readMessage(field2, googleProtobufTimestamp.Timestamp.deserializeBinaryFromReader);
				message.setEndAfter(field2);
				break;
			default:
				reader.skipField();
				break;
			}
		}
		return message;
	}

}


function ListAvailabilitiesReqFromObject(obj: ListAvailabilitiesReq.AsObject | undefined): ListAvailabilitiesReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new ListAvailabilitiesReq();
	message.setPage(PageQueryDTOFromObject(obj.page));
	message.setProjectId(StringValueFromObject(obj.projectId));
	message.setResourceId(StringValueFromObject(obj.resourceId));
	message.setStartTime(TimestampFromObject(obj.startTime));
	message.setEndTime(TimestampFromObject(obj.endTime));
	return message;
}

function PageQueryDTOFromObject(obj: core.PageQueryDTO.AsObject | undefined): core.PageQueryDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new core.PageQueryDTO();
	message.setPage(Int32ValueFromObject(obj.page));
	message.setPageSize(Int32ValueFromObject(obj.pageSize));
	return message;
}

function Int32ValueFromObject(obj: googleProtobufWrappers.Int32Value.AsObject | undefined): googleProtobufWrappers.Int32Value | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new googleProtobufWrappers.Int32Value();
	message.setValue(obj.value);
	return message;
}

function StringValueFromObject(obj: googleProtobufWrappers.StringValue.AsObject | undefined): googleProtobufWrappers.StringValue | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new googleProtobufWrappers.StringValue();
	message.setValue(obj.value);
	return message;
}

function TimestampFromObject(obj: googleProtobufTimestamp.Timestamp.AsObject | undefined): googleProtobufTimestamp.Timestamp | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new googleProtobufTimestamp.Timestamp();
	message.setSeconds(obj.seconds);
	message.setNanos(obj.nanos);
	return message;
}

function ListAvailabilitiesResFromObject(obj: ListAvailabilitiesRes.AsObject | undefined): ListAvailabilitiesRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new ListAvailabilitiesRes();
	message.setPage(PageDTOFromObject(obj.page));
	(obj.items || [])
		.map((item) => AvailabilityDTOFromObject(item))
		.forEach((item) => message.addItems(item));
	return message;
}

function PageDTOFromObject(obj: core.PageDTO.AsObject | undefined): core.PageDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new core.PageDTO();
	message.setPage(obj.page);
	message.setPageSize(obj.pageSize);
	message.setTotalCount(obj.totalCount);
	return message;
}

function AvailabilityDTOFromObject(obj: AvailabilityDTO.AsObject | undefined): AvailabilityDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new AvailabilityDTO();
	message.setId(obj.id);
	message.setMetadata(MetadataDTOFromObject(obj.metadata));
	message.setStartTime(TimestampFromObject(obj.startTime));
	message.setEndTime(TimestampFromObject(obj.endTime));
	message.setMaxParticipants(obj.maxParticipants);
	message.setResource(ResourceDTOFromObject(obj.resource));
	message.setRepeat(RepeatDTOFromObject(obj.repeat));
	message.setProject(ProjectDTOFromObject(obj.project));
	message.setLocation(StringValueFromObject(obj.location));
	message.setUrl(StringValueFromObject(obj.url));
	message.setColor(StringValueFromObject(obj.color));
	message.setDescription(StringValueFromObject(obj.description));
	message.setCost(StringValueFromObject(obj.cost));
	message.setTitle(StringValueFromObject(obj.title));
	message.setImage(StringValueFromObject(obj.image));
	message.setAuthTags(AuthorizationTagsDTOFromObject(obj.authTags));
	message.setDisableActions(obj.disableActions);
	return message;
}

function MetadataDTOFromObject(obj: core.MetadataDTO.AsObject | undefined): core.MetadataDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new core.MetadataDTO();
	message.setCreatedAt(TimestampFromObject(obj.createdAt));
	message.setUpdatedAt(TimestampFromObject(obj.updatedAt));
	return message;
}

function ResourceDTOFromObject(obj: resources.ResourceDTO.AsObject | undefined): resources.ResourceDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new resources.ResourceDTO();
	message.setId(obj.id);
	message.setTitle(obj.title);
	message.setProjectId(obj.projectId);
	message.setDescription(StringValueFromObject(obj.description));
	message.setImage(StringValueFromObject(obj.image));
	return message;
}

function RepeatDTOFromObject(obj: RepeatDTO.AsObject | undefined): RepeatDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new RepeatDTO();
	message.setFrequency(obj.frequency);
	message.setEndAfter(TimestampFromObject(obj.endAfter));
	return message;
}

function ProjectDTOFromObject(obj: projects.ProjectDTO.AsObject | undefined): projects.ProjectDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new projects.ProjectDTO();
	message.setId(obj.id);
	message.setName(obj.name);
	message.setTenantId(obj.tenantId);
	return message;
}

function AuthorizationTagsDTOFromObject(obj: core.AuthorizationTagsDTO.AsObject | undefined): core.AuthorizationTagsDTO | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new core.AuthorizationTagsDTO();
	(obj.tagsMap || [])
		.forEach((entry) => message.getTagsMap().set(entry[0], entry[1]));
	return message;
}

function CreateAvailabilityReqFromObject(obj: CreateAvailabilityReq.AsObject | undefined): CreateAvailabilityReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateAvailabilityReq();
	message.setStartTime(TimestampFromObject(obj.startTime));
	message.setEndTime(TimestampFromObject(obj.endTime));
	message.setMaxParticipants(Int32ValueFromObject(obj.maxParticipants));
	message.setResourceId(obj.resourceId);
	message.setRepeat(RepeatDTOFromObject(obj.repeat));
	message.setAuthTags(AuthorizationTagsDTOFromObject(obj.authTags));
	message.setLocation(StringValueFromObject(obj.location));
	message.setUrl(StringValueFromObject(obj.url));
	message.setColor(StringValueFromObject(obj.color));
	message.setDescription(StringValueFromObject(obj.description));
	message.setCost(StringValueFromObject(obj.cost));
	message.setTitle(StringValueFromObject(obj.title));
	message.setImage(StringValueFromObject(obj.image));
	message.setDisableActions(BoolValueFromObject(obj.disableActions));
	return message;
}

function BoolValueFromObject(obj: googleProtobufWrappers.BoolValue.AsObject | undefined): googleProtobufWrappers.BoolValue | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new googleProtobufWrappers.BoolValue();
	message.setValue(obj.value);
	return message;
}

function CreateAvailabilityResFromObject(obj: CreateAvailabilityRes.AsObject | undefined): CreateAvailabilityRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new CreateAvailabilityRes();
	message.setAvailability(AvailabilityDTOFromObject(obj.availability));
	return message;
}

function UpdateAvailabilityReqFromObject(obj: UpdateAvailabilityReq.AsObject | undefined): UpdateAvailabilityReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new UpdateAvailabilityReq();
	message.setId(obj.id);
	message.setStartTime(TimestampFromObject(obj.startTime));
	message.setEndTime(TimestampFromObject(obj.endTime));
	message.setMaxParticipants(Int32ValueFromObject(obj.maxParticipants));
	message.setRepeat(RepeatDTOFromObject(obj.repeat));
	message.setLocation(StringValueFromObject(obj.location));
	message.setUrl(StringValueFromObject(obj.url));
	message.setColor(StringValueFromObject(obj.color));
	message.setDescription(StringValueFromObject(obj.description));
	message.setCost(StringValueFromObject(obj.cost));
	message.setTitle(StringValueFromObject(obj.title));
	message.setImage(StringValueFromObject(obj.image));
	message.setDisableActions(BoolValueFromObject(obj.disableActions));
	return message;
}

function UpdateAvailabilityResFromObject(obj: UpdateAvailabilityRes.AsObject | undefined): UpdateAvailabilityRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new UpdateAvailabilityRes();
	message.setAvailability(AvailabilityDTOFromObject(obj.availability));
	return message;
}

function DeleteAvailabilityReqFromObject(obj: DeleteAvailabilityReq.AsObject | undefined): DeleteAvailabilityReq | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new DeleteAvailabilityReq();
	message.setId(obj.id);
	return message;
}

function DeleteAvailabilityResFromObject(obj: DeleteAvailabilityRes.AsObject | undefined): DeleteAvailabilityRes | undefined {
	if (obj === undefined) {
		return undefined;
	}
	const message = new DeleteAvailabilityRes();
	return message;
}

