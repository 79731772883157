import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';

export function toDate(timestamp: Timestamp.AsObject | undefined): Date {
  const t = new Timestamp();
  if (timestamp) {
    t.setSeconds(timestamp.seconds);
    t.setNanos(timestamp.nanos);
  }
  return t.toDate();
}

export function combine(inputDate: Date, inputTime: Date): Date {
  const date = inputDate;
  const time = new Date(`'01/01/1970 ${inputTime}`);
  date.setHours(time.getHours());
  date.setMinutes(time.getMinutes());
  return date;
}

export function dateToTimestamp(date: Date): Timestamp.AsObject {
  const timeInt: number = Math.round(date.getTime() / 1000);
  const timestamp = new Timestamp();
  timestamp.setSeconds(timeInt);
  timestamp.setNanos(0);
  return timestamp.toObject();
}
